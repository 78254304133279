export function GetAppsNotificationTypes() {
  return [
    {
      key: 0,
      description: "All"
    },
    {
      key: 1,
      description: "SpecificUser"
    }
  ];
}

export function GetAppNotificationTypeDescription(value) {
  let appsNotificationType = GetAppsNotificationTypes().find(
    item => item.key === value
  );
  return appsNotificationType.description;
}

export function GetAppNotificationTypeKey(value) {
  let appsNotificationType = GetAppsNotificationTypes().find(
    item => item.description === value
  );
  return appsNotificationType.key;
}
