import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

import { bookingApi } from "functions/apis";
const useStyles = makeStyles(styles);

export default function Create(props) {
  const source = useRef(new AbortController());

  const [feePolicyList, setFeePolicyList] = React.useState([]);

  useEffect(() => {
    if (
      token["booking.fee_policies.read"] === "allowed" ||
      token.is_admin === "True"
    )
      bookingApi
        .get("/FeePolicies", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setFeePolicyList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);
  const handleCodeChanged = e =>
    setState({ ...state, code: e.target.value === "" ? null : e.target.value });

  const handleNameChanged = e =>
    setState({ ...state, name: e.target.value === "" ? null : e.target.value });

  const handleCommonFeePolicyIDChanged = e =>
    setState({ ...state, commonFeePolicyID: e.target.value });

  const handlePeakHourFeePolicyIDChanged = e =>
    setState({ ...state, peakHourFeePolicyID: e.target.value });

  const [state, setState] = React.useState({
    code: null,
    name: null,
    commonFeePolicyID: null,
    peakHourFeePolicyID: null
  });

  const classes = useStyles();

  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/FeePolicyGroups", state, {
        signal: source.current.signal
      })
      .then(data =>
        setRedirection({
          redirect: true,
          id: data
        })
      )
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirection.redirect ? (
    <Redirect to={"/admin/FeePolicyGroups/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE FEE POLICY GROUP</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleCodeChanged,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: state.name === null ? "" : state.name
                }}
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCommonFeePolicy"
                  className={classes.selectLabel}
                >
                  Common Fee Policy
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    state.commonFeePolicyID === null
                      ? ""
                      : state.commonFeePolicyID
                  }
                  onChange={handleCommonFeePolicyIDChanged}
                >
                  {feePolicyList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.code}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selPeakHourFeePolicy"
                  className={classes.selectLabel}
                >
                  Peak Hour Fee Policy
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    state.peakHourFeePolicyID === null
                      ? ""
                      : state.peakHourFeePolicyID
                  }
                  onChange={handlePeakHourFeePolicyIDChanged}
                >
                  {feePolicyList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.code}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Create.propTypes = {
  history: PropTypes.object
};
