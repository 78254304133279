import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "components/CustomButtons/Button.js";

import { bookingApi } from "functions/apis";
import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import { GetOnlineBookingPaymentMethodDescription } from "enums/OnlineBookingPaymentMethod";
import { formatDateTime } from "functions/Helper";
import { formatTripDate } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const sortOptionsList = [
  "NO_ASC",
  "NO_DESC",
  "TRANSACTION TIME ASC",
  "TRANSACTION TIME DESC"
];

const useStyles = makeStyles(styles);

export default function PaidList() {
  // const [loading, setLoading] = useEffect(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = useState({
    searchString: "",
    status: 2,
    sort: "NO_DESC"
  });
  const [pages, setPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const onlineBookingListSignal = new AbortController();

  const getBookingData = () => {
    setLoading(true);

    bookingApi
      .get("OnlineBookings", {
        signal: onlineBookingListSignal.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/OnlineBooking/Detail/" + record.id}>
                    <Button
                      color="info"
                      className="like"
                      type="button"
                      value="VIEW"
                    >
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getBookingData();
    return () => {
      onlineBookingListSignal.abort();
    };
  }, []);

  useEffect(() => {
    getBookingData();
  }, [pagination, filter]);

  const handleSortOptionsChanged = e =>
    setFilter({ ...filter, sort: e.target.value });

  const handleSearchStringChanged = e =>
    setFilter({ ...filter, searchString: e.target.value });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Online Booking - Paid</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    SORT BY
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={filter.sort ?? ""}
                    onChange={handleSortOptionsChanged}
                  >
                    {sortOptionsList.map((record, idx) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={idx}
                          key={idx}
                        >
                          <p>{record}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value: filter.searchString ?? ""
                  }}
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              pageSize={pagination.pageSize}
              page={pagination.pageIndex - 1}
              onPageChange={page => {
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                });
              }}
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "No",
                  accessor: "no"
                },
                {
                  id: "transactionTime",
                  Header: "Transaction Time",
                  accessor: d =>
                    formatDateTime(new Date(d.transactionTime + "Z"))
                },
                {
                  Header: "Confirmation Code",
                  accessor: "confirmationCode"
                },
                {
                  id: "user",
                  Header: "User",
                  accessor: d => d.user.name.toString()
                },
                {
                  id: "isRoundTrip",
                  Header: "Is Round Trip",
                  accessor: d => (d.isRoundTrip ? "Yes" : "No")
                },
                {
                  id: "departure",
                  Header: "Departure",
                  accessor: d => (
                    <>
                      <p>{d.latestDepartureCoreApiTrip.route.code}</p>
                      <p>
                        {formatTripDate(
                          new Date(d.latestDepartureCoreApiTrip.date)
                        )}
                      </p>
                      <p>{d.latestDepartureCoreApiTrip.time}</p>
                    </>
                  )
                },
                {
                  id: "return",
                  Header: "Return",
                  accessor: d =>
                    d.isRoundTrip ? (
                      d.latestIsReturnTripOpen ? (
                        <p>OPEN RETURN TRIP</p>
                      ) : (
                        <>
                          <p>{d.latestReturnCoreApiTrip.route.code}</p>
                          <p>
                            {formatTripDate(
                              new Date(d.latestReturnCoreApiTrip.date)
                            )}
                          </p>
                          <p>{d.latestReturnCoreApiTrip.time}</p>
                        </>
                      )
                    ) : (
                      <p>NO RETURN</p>
                    )
                },
                {
                  id: "paymentMethod",
                  Header: "Payment Method",
                  accessor: d =>
                    GetOnlineBookingPaymentMethodDescription(d.paymentMethod)
                },
                {
                  id: "currency",
                  Header: "Currency",
                  accessor: d => d.currency.code.toString()
                },
                {
                  id: "grandTotal",
                  Header: "Grand Total",
                  accessor: d => d.grandTotal.toLocaleString()
                },
                {
                  id: "promoCodeDiscount",
                  Header: "Promo Code Disc.",
                  accessor: d => d.promoCodeDiscount.toLocaleString()
                },
                {
                  id: "promoCode",
                  Header: "Promo Code",
                  accessor: d => d.onlineBookingPromoCode?.promoCode
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
