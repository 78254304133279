import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { useParams } from "react-router-dom";
import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

const EditAppsNotification = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [input, setInput] = useState({
    title: "",
    description: "",
    url: "",
    NotificationSectionID: "",
    name: ""
  });
  const [section, setSection] = useState([]);
  const { id } = useParams();

  const EditAppsNotificationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => {
      EditAppsNotificationSignal.current.abort();
      setRedirect(disabled);
    };
  }, []);

  useEffect(() => {
    if (id) {
      bookingApi
        .get("appsnotifications/" + id, {
          signal: EditAppsNotificationSignal.current.signal
        })
        .then(resp => {
          setInput({
            ...input,
            name: resp.name ?? "",
            title: resp.title ?? "",
            description: resp.description ?? "",
            NotificationSectionID: resp.notificationSection
              ? resp.notificationSection.id
              : "",
            url: resp.url ?? ""
          });
        });

      bookingApi
        .get("appsNotificationSections", {
          signal: EditAppsNotificationSignal.current.signal,
          params: {
            filter: {},
            pagination: {}
          }
        })
        .then(resp => {
          setSection(resp.records);
        });
    }
  }, [id]);

  const handleNameChanged = e =>
    setInput({
      ...input,
      name: e.target.value === "" ? null : e.target.value
    });

  const handleTitleChanged = e =>
    setInput({
      ...input,
      title: e.target.value === "" ? null : e.target.value
    });

  const handleDescriptionChanged = e =>
    setInput({
      ...input,
      description: e.target.value === "" ? null : e.target.value
    });

  const handlePushNotificationSectionChanged = e =>
    setInput({
      ...input,
      NotificationSectionID: e.target.value === "" ? null : e.target.value
    });

  const handleUrlChanged = e =>
    setInput({
      ...input,
      url: e.target.value === "" ? null : e.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .put("appsnotifications/" + id, input, {
        signal: EditAppsNotificationSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/AppsNotification/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Edit Apps Notification</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: input.name
                }}
              />
              <CustomInput
                labelText="Notification Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTitleChanged,
                  value: input.title
                }}
              />
              <CustomInput
                labelText="Notification Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleDescriptionChanged,
                  value: input.description
                }}
              />
              <CustomInput
                labelText="Url"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleUrlChanged,
                  value: input.url ?? ""
                }}
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Section
                </InputLabel>
                <Select
                  MenuProps={{
                    classes: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={input.NotificationSectionID ?? ""}
                  onChange={handlePushNotificationSectionChanged}
                >
                  {section.map(val => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      key={val.id}
                      value={val.id}
                    >
                      <b>{val.name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Edit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditAppsNotification;

EditAppsNotification.propTypes = {
  history: PropTypes.object
};
