import React, { useEffect, useRef } from "react";
import { Assignment, Check } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CardBody from "components/Card/CardBody";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { bookingApi } from "functions/apis";
import ReactTable from "react-table";
import EditIcon from "@material-ui/icons/Edit";
import CustomInput from "components/CustomInput/CustomInput";
import { formatDateTime } from "functions/Helper";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(styles);

export default function MerchantPromo() {
  const classes = useStyles();
  const source = useRef(new AbortController());

  const [filter, setFilter] = React.useState({
    searchString: "",
    currentActive: false
  });
  const [data, setData] = React.useState([]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [loading, setLoading] = React.useState(false);
  const [pages, setPages] = React.useState(0);

  const getDataMerchantPromo = () => {
    setLoading(true);
    bookingApi
      .get("MerchantPromos", {
        signal: source.current.signal,
        params: {
          filter,
          pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(records => {
            return {
              ...records,
              actions: (
                <div className="actions-right">
                  <>
                    <Link to={"/admin/MerchantPromo/Detail/" + records.id}>
                      <Button color="info" className="like">
                        <VisibilityIcon /> VIEW
                      </Button>
                    </Link>
                    <Link to={"/admin/MerchantPromo/Edit/" + records.id}>
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() => {}}
                      >
                        <EditIcon /> EDIT
                      </Button>
                    </Link>
                  </>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDataMerchantPromo();

    return () => source.current.abort();
  }, []);

  useEffect(() => {
    getDataMerchantPromo();
  }, [filter, pagination]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleCurrentActiveChanged = e =>
    setFilter({
      ...filter,
      currentActive: e.target.checked
    });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Merchant Promo</h4>
          </CardHeader>
          <CardBody>
            <Link to={"/admin/MerchantPromo/Create"}>
              <Button color="rose" type="button" value="Create">
                Create
              </Button>
            </Link>

            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <GridItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.currentActive}
                    onChange={handleCurrentActiveChanged}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Current Active"
              />
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "title",
                    Header: "Title",
                    accessor: d => d.title
                  },
                  {
                    id: "description",
                    Header: "Description",
                    accessor: d => d.description
                  },
                  {
                    id: "limit",
                    Header: "Limit",
                    accessor: d => d.limit
                  },
                  {
                    id: "used",
                    Header: "Used",
                    accessor: d => d.used
                  },
                  {
                    id: "merchant",
                    Header: "Merchant",
                    accessor: d => d.merchant.code
                  },
                  {
                    id: "effectiveDate",
                    Header: "Effective Date",
                    accessor: d =>
                      formatDateTime(new Date(d.effectiveDate + "Z"))
                  },
                  {
                    id: "expiryDate",
                    Header: "Expiry Date",
                    accessor: d =>
                      d.expiryDate === null
                        ? ""
                        : formatDateTime(new Date(d.expiryDate + "Z"))
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
