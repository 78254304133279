import { Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import "react-datetime/css/react-datetime.css";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { masterApi } from "functions/apis";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import { tripDateFormat } from "functions/Helper";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import moment from "moment";
import { GetOnlineBookingPromoCodeCalculationTypes } from "enums/OnlineBookingPromoCodeCalculationType";

const useStyles = makeStyles(styles);

export default function CreateOnlineBookingsPromoCode(props) {
  const [redirect, setRedirect] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [state, setState] = useState({
    promoCode: "",
    effectiveDate: null,
    expiryDate: null,
    currencyID: null,
    calculationType: 0,
    discountPercentage: 0,
    maximumDiscountAmount: 0,
    discountAmount: 0,
    limit: 0,
    minimalTrip: 0,
    hasDayGroupRestriction: false,
    hasSectorRestriction: false,
    hasUserRestriction: false,
    userEmail: null,
    allowedDayGroupID: null,
    allowedSectorIDs: [],
    hasTravelDateRestriction: false,
    travelDateStart: null,
    travelDateUntil: null,
    hasTimeRestriction: false,
    time: null,
    isRestrictedToCardPayment: false,
    cardNumberPrefix: null,
    hasMinimalAppVersion: false,
    minimalAndroidAppVersion: "",
    minimalIOSAppVersion: ""
  });
  const [disabled, setDisabled] = React.useState(false);
  const [currencyList, setCurrencyList] = React.useState([]);
  const [dayGroupList, setDayGroupList] = React.useState([]);

  const abortController = React.useRef(new AbortController());

  const getDayGroups = () => {
    masterApi
      .get("/DayGroups", {
        signal: abortController.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => {
        setDayGroupList(data.records);
      })
      .catch(error => console.log(error));
  };

  const getSector = () => {
    masterApi
      .get("/Sectors", {
        signal: abortController.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => {
        setSectorList(data.records);
      })
      .catch(error => console.log(error));
  };

  const getCurrency = () => {
    masterApi
      .get("/Currencies", {
        signal: abortController.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getSector();
    getCurrency();
    getDayGroups();

    return () => abortController.current.abort();
  }, []);

  const handlePromoCodeChanged = e =>
    setState({ ...state, promoCode: e.target.value });

  const generateRandomCode = () =>
    setState({
      ...state,
      promoCode:
        state.promoCode +
        Math.random()
          .toString(36)
          .slice(2, 8)
          .toUpperCase()
    });

  const handleUserEmailChanged = e =>
    setState({ ...state, userEmail: e.target.value });

  const handleCalculationTypeChanged = e =>
    setState({ ...state, calculationType: e.target.value });

  const handleMaximumDiscountAmountChanged = e =>
    setState({ ...state, maximumDiscountAmount: e.target.value });

  const handleDiscountPercentageChanged = e =>
    setState({ ...state, discountPercentage: e.target.value });

  const handleDiscountAmountChanged = e =>
    setState({ ...state, discountAmount: e.target.value });

  const handleLimitChanged = e => setState({ ...state, limit: e.target.value });

  const handleMinimalTripChanged = e =>
    setState({ ...state, minimalTrip: e.target.value });

  const handleCurrencyIDChanged = e => {
    setState({ ...state, currencyID: e.target.value });
  };

  const handleHasDayGroupRestrictionChanged = e =>
    setState({ ...state, hasDayGroupRestriction: e.target.checked });

  const handleAllowedDayGroupIDChanged = e =>
    setState({ ...state, allowedDayGroupID: e.target.value });

  const handleAllowedSectorIDsChanged = e =>
    setState({ ...state, allowedSectorIDs: e.target.value });

  const handleHasSectorRestrictionChanged = e =>
    setState({ ...state, hasSectorRestriction: e.target.checked });

  const handleHasUserRestrictionChanged = e =>
    setState({ ...state, hasUserRestriction: e.target.checked });

  const handleHasTravelDateRestrictionChanged = e =>
    setState({ ...state, hasTravelDateRestriction: e.target.checked });

  const handleHasRestrictedToCardPayment = e =>
    setState({ ...state, isRestrictedToCardPayment: e.target.checked });

  const handleHasTimeRestrictionsChanged = e =>
    setState({ ...state, hasTimeRestriction: e.target.checked });

  const handleCardNumberPrefixChanged = e =>
    setState({ ...state, cardNumberPrefix: e.target.value });

  const handleHasMinimalAppVersionChanged = e =>
    setState({ ...state, hasMinimalAppVersion: e.target.checked });

  const handleMinimalAndroidAppVersionChanged = e =>
    setState({ ...state, minimalAndroidAppVersion: e.target.value });

  const handleMinimalIOSAppVersionChanged = e =>
    setState({ ...state, minimalIOSAppVersion: e.target.value });

  const handleSubmit = () => {
    setDisabled(true);
    const body = { ...state };
    body.time = body.time ? moment(body.time).format("HHmm") : null;
    bookingApi
      .post("/OnlineBookingPromoCodes", body, {
        signal: abortController.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/OnlineBookingPromoCodesList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CREATE ONLINE BOOKING PROMO CODES
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <GridContainer>
              <GridItem xs={6} md={6}>
                <CustomInput
                  labelText="Promo Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handlePromoCodeChanged,
                    value: state.promoCode
                  }}
                />
              </GridItem>
              <GridItem xs={6} md={6}>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="button"
                  value="Generate Random Code"
                  onClick={() => generateRandomCode()}
                >
                  Generate Random Code
                </Button>
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Minimal Trip"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: handleMinimalTripChanged,
                    value: state.minimalTrip
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Limit"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: handleLimitChanged,
                    value: state.limit
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <InputLabel className={classes.selectLabel}>
                  Effective Date
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.effectiveDate}
                    onChange={e =>
                      setState({
                        ...state,
                        effectiveDate: e === "" ? null : e
                      })
                    }
                    dateFormat={tripDateFormat}
                    timeFormat={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Effective Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <InputLabel className={classes.selectLabel}>
                  Expiry Date
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.expiryDate}
                    onChange={e =>
                      setState({
                        ...state,
                        expiryDate: e === "" ? null : e
                      })
                    }
                    dateFormat={tripDateFormat}
                    timeFormat={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Expiry Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    Currency
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.currencyID ?? ""}
                    onChange={handleCurrencyIDChanged}
                  >
                    {currencyList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <b>{record.code}</b>
                          <br />
                          {record.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCalculationType"
                    className={classes.selectLabel}
                  >
                    CALCULATION TYPE
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "selCalculationType",
                      name: "selCalculationType"
                    }}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.calculationType}
                    onChange={handleCalculationTypeChanged}
                  >
                    {GetOnlineBookingPromoCodeCalculationTypes().map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.key}
                          key={record.key}
                        >
                          <p>{record.description}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              {state.calculationType === 0 && (
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="Discount Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleDiscountAmountChanged,
                      value: state.discountAmount
                    }}
                  />
                </GridItem>
              )}
              {state.calculationType === 1 && (
                <>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText="Discount Percentage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleDiscountPercentageChanged,
                        value: state.discountPercentage
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText="Maximum Discount Amount"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleMaximumDiscountAmountChanged,
                        value: state.maximumDiscountAmount
                      }}
                    />
                  </GridItem>
                </>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasUserRestriction}
                      onChange={handleHasUserRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has User Restriction"
                />
              </GridItem>
              {state.hasUserRestriction && (
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="User Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleUserEmailChanged,
                      value: state.userEmail
                    }}
                  />
                </GridItem>
              )}
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleHasDayGroupRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has DayGroup Restriction"
                />
              </GridItem>
              {state.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selDayGroup"
                      className={classes.selectLabel}
                    >
                      Allowed DayGroup
                    </InputLabel>
                    <Select
                      inputProps={{
                        id: "selDayGroup",
                        name: "selDayGroup"
                      }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        state.allowedDayGroupID === null
                          ? ""
                          : state.allowedDayGroupID
                      }
                      onChange={handleAllowedDayGroupIDChanged}
                    >
                      {dayGroupList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{record.name}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasSectorRestriction}
                      onChange={handleHasSectorRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Sector Restriction"
                />
              </GridItem>
              {state.hasSectorRestriction && (
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAllowedSectors"
                      className={classes.selectLabel}
                    >
                      Allowed Sectors
                    </InputLabel>
                    <Select
                      multiple
                      inputProps={{
                        id: "selAllowedSectors",
                        name: "selAllowedSectors"
                      }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={state.allowedSectorIDs}
                      onChange={handleAllowedSectorIDsChanged}
                      renderValue={() => (
                        <div className={classes.chips}>
                          {state.allowedSectorIDs.map(value => {
                            let sector = sectorList.find(
                              item => item.id === value
                            );
                            return (
                              <div key={value} className={classes.chips}>
                                <Chip
                                  key={sector.id}
                                  label={<b>{sector.name}</b>}
                                  className={classes.chip}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    >
                      {sectorList.map(record => {
                        let sector = sectorList.find(
                          item => item.id === record.id
                        );
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{sector.code}</b> <br />
                            {sector.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasTravelDateRestriction}
                      onChange={handleHasTravelDateRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Travel Date Restriction"
                />
              </GridItem>
              {state.hasTravelDateRestriction && (
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Travel Date Start
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.travelDateStart}
                      onChange={e =>
                        setState({
                          ...state,
                          travelDateStart: e === "" ? null : e
                        })
                      }
                      dateFormat={tripDateFormat}
                      timeFormat={false}
                      utc={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "TravelDate Start"
                      }}
                    />
                  </FormControl>
                </GridItem>
              )}
              {state.hasTravelDateRestriction && (
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Travel Date Until
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.travelDateUntil}
                      onChange={e =>
                        setState({
                          ...state,
                          travelDateUntil: e === "" ? null : e
                        })
                      }
                      dateFormat={tripDateFormat}
                      timeFormat={false}
                      utc={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "TravelDate Until"
                      }}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasTimeRestriction}
                      onChange={handleHasTimeRestrictionsChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Time Restriction"
                />
              </GridItem>
              {state.hasTimeRestriction && (
                <GridItem xs={12} md={12}>
                  <InputLabel htmlFor="txtTime" className={classes.selectLabel}>
                    Travel Time
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.time}
                      onChange={e =>
                        setState({
                          ...state,
                          time: e === "" ? null : e
                        })
                      }
                      dateFormat={false}
                      timeFormat={true}
                      inputProps={{
                        placeholder: "Time",
                        id: "txtTime",
                        name: "txtTime"
                      }}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isRestrictedToCardPayment}
                      onChange={handleHasRestrictedToCardPayment}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Restricted To Card Payment"
                />
              </GridItem>
              {state.isRestrictedToCardPayment && (
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="Card Number Prefix"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleCardNumberPrefixChanged,
                      value: state.cardNumberPrefix
                    }}
                  />
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasMinimalAppVersion}
                      onChange={handleHasMinimalAppVersionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Minimal App Version"
                />
              </GridItem>
              {state.hasMinimalAppVersion && (
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Minimal Android App Version"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleMinimalAndroidAppVersionChanged,
                      value: state.minimalAndroidAppVersion
                    }}
                  />
                </GridItem>
              )}
              {state.hasMinimalAppVersion && (
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Minimal IOS App Version"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleMinimalIOSAppVersionChanged,
                      value: state.minimalIOSAppVersion
                    }}
                  />
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateOnlineBookingsPromoCode.propTypes = {
  history: PropTypes.object
};
