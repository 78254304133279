import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { adminApi } from "functions/apis";

import UserRoleList from "./UserRoleList";
import UserPermissionList from "./UserPermissionList";
import UserAvailablePermissionList from "./UserAvailablePermissionList";

const useStyles = makeStyles(styles);

export default function UserDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id } = useParams();
  const [reload, setReload] = React.useState({});
  const reloadHandler = () => {
    setReload({});
  };
  const [user, setUser] = React.useState(null);
  useEffect(() => {
    adminApi
      .get("/Users/" + id, {
        signal: source.current.signal
      })
      .then(data => setUser(data))
      .catch(error => console.log(error));
  }, [id, reload]);

  const classes = useStyles();
  return (
    user !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>USER</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <CustomInput
                labelText="UserName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: user.userName === null ? "" : user.userName
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: user.email === null ? "" : user.email
                }}
              />
              <GridItem>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={user.isSuperAdmin}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Is Super Admin"
                />
                <GridItem></GridItem>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={user.isAdmin}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Is Admin"
                />
              </GridItem>
            </CardBody>
          </Card>
          <UserRoleList id={id} />
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <UserPermissionList
                id={id}
                reloadHandler={reloadHandler}
                reload={reload}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <UserAvailablePermissionList
                id={id}
                reloadHandler={reloadHandler}
                reload={reload}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  );
}

UserDetail.propTypes = {
  history: PropTypes.object
};
