import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function ViewBookingType(props) {
  const { bookingType } = props;
  const classes = useStyles();
  return (
    <>
      <CustomInput
        labelText="Code"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: bookingType.code
        }}
      />

      <CustomInput
        labelText="Name"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: bookingType.name
        }}
      />
      <GridContainer>
        <GridItem md={6} xs={12}>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={bookingType.isRoundTrip}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="Is Round Trip"
          />
        </GridItem>
        <GridItem md={6} xs={12}>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={bookingType.isVTL}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label="Is VTL"
          />
        </GridItem>
      </GridContainer>
      <CustomInput
        labelText="Departure Sector"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: bookingType.departureSector.code
        }}
      />
      <div className={classes.checkboxAndRadio}>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={bookingType.hasPaxTypeRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has PaxType Restriction"
              />
            </GridItem>
            {bookingType.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
              <GridItem>
                <CustomInput
                  labelText="Allowed PaxType"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: bookingType.allowedPaxType.name
                  }}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem xs={12} sm={4}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={bookingType.hasNationalityRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has Nationality Restriction"
              />
            </GridItem>
            {bookingType.hasNationalityRestriction && ( // render only if Has PaxType Restriction
              <GridItem>
                <CustomInput
                  labelText="Allowed Nationality"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: bookingType.allowedNationality.nationality
                  }}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem xs={12} sm={4}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={bookingType.hasDayGroupRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has DayGroup Restriction"
              />
            </GridItem>
            {bookingType.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
              <GridItem>
                <CustomInput
                  labelText="Allowed DayGroup"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: bookingType.allowedDayGroup.name
                  }}
                />
              </GridItem>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <CustomInput
        labelText="Additional Criteria String"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: bookingType.additionalCriteriaString
        }}
      />
    </>
  );
}

ViewBookingType.propTypes = {
  bookingType: PropTypes.object
};
