import React, { useEffect, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Replay from "@material-ui/icons/Replay";
// core components
import { Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { bookingApi } from "functions/apis";
import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const source = useRef();
  // const source = useRef(new AbortController());

  const [coreApiCheckInStats, setCoreApiCheckInStats] = React.useState(null);
  const [
    coreApiCheckInStatsQueriedTime,
    setCoreApiCheckInStatsQueriedTime
  ] = React.useState(null);
  const [
    reloadCoreApiCheckInStats,
    setReloadCoreApiCheckInStats
  ] = React.useState({});
  const [
    coreApiCheckInStatsLoading,
    setCoreApiCheckInStatsLoading
  ] = React.useState(false);
  useEffect(() => {
    const abortSignal = new AbortController();
    source.current = abortSignal;
    if (
      token["booking.core_api_check_ins.get_stats"] === "allowed" ||
      token.is_admin === "True"
    ) {
      setCoreApiCheckInStatsLoading(true);
      bookingApi
        .get("/CoreApiCheckIns/Stats", {
          // signal: source.current.signal
          signal: source.current.signal
        })
        .then(data => {
          setCoreApiCheckInStats(data);
          setCoreApiCheckInStatsQueriedTime(new Date());
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => setCoreApiCheckInStatsLoading(false));
    }

    return () => source.current.abort();
  }, [reloadCoreApiCheckInStats]);

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        {coreApiCheckInStats !== null && (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <h3 className={classes.cardTitle}>Check In Stats</h3>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                    ["Pending", coreApiCheckInStats.totalPending],
                    [
                      "CheckIn Enqueued",
                      coreApiCheckInStats.totalCheckInEnqueued
                    ],
                    ["CheckIn Error", coreApiCheckInStats.totalCheckInError],
                    [
                      "Cancel Enqueued",
                      coreApiCheckInStats.totalCancelEnqueued
                    ],
                    ["Cancel Error", coreApiCheckInStats.totalCancelError],
                    [
                      "Passport Expiry",
                      coreApiCheckInStats.passportExpiryDateAlert
                    ]
                  ]}
                />
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer />
                  Queried at{" "}
                  {coreApiCheckInStatsQueriedTime !== null &&
                    coreApiCheckInStatsQueriedTime.toString()}
                </div>

                <Button
                  disabled={coreApiCheckInStatsLoading}
                  onClick={() => setReloadCoreApiCheckInStats({})}
                  value="Reload"
                >
                  <Replay className={classes.underChartIcons} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
