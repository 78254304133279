import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import ViewVoucherType from "views/VoucherType/ViewVoucherType";

import { voucherApi } from "functions/apis";

export default function DirectIssuanceDetail() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  let { id } = useParams();

  const [directIssuance, setDirectIssuance] = React.useState(null);
  const [voucherType, setVoucherType] = React.useState(null);
  useEffect(() => {
    voucherApi
      .get("/DirectIssuances/" + id, {
        signal: source.current.signal
      })
      .then(data => {
        setDirectIssuance(data);
        voucherApi
          .get("/VoucherTypes/" + data.voucherType.id, {
            signal: source.current.signal
          })
          .then(res => setVoucherType(res))
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  }, [id]);

  return (
    directIssuance !== null && (
      <>
        <CustomInput
          disabled
          labelText="No"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: directIssuance.no
          }}
        />
        <CustomInput
          disabled
          labelText="Voucher Type"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: directIssuance.voucherType.code
          }}
        />
        <CustomInput
          disabled
          labelText="Quantity"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: directIssuance.quantity.toLocaleString()
          }}
        />
        <CustomInput
          disabled
          labelText="Remarks"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: directIssuance.remarks
          }}
        />
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              disabled
              labelText="Currency"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: directIssuance.currency.code
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText="Unit Price"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: directIssuance.unitPrice
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText="Total"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: directIssuance.quantity * directIssuance.unitPrice
              }}
            />
          </GridItem>
        </GridContainer>
        {voucherType !== null && <ViewVoucherType voucherType={voucherType} />}
      </>
    )
  );
}

DirectIssuanceDetail.propTypes = {
  history: PropTypes.object
};
