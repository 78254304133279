import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import NotificationIcon from "@material-ui/icons/Notifications";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/navbarLinksStyle.js";

import { adminApi } from "functions/apis";

import GetNotification from "functions/NotificationHelper";
import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function NavbarLinks(props) {
  const source = useRef(new AbortController());
  const [unread, setUnread] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  useEffect(() => {
    const checkNotification = () => {
      setReloadNotification({});
      setTimeout(checkNotification, 60000);
    };
    setTimeout(checkNotification, 60000);
    return () => source.current.abort();
  }, []);

  const [reloadNotification, setReloadNotification] = React.useState({});
  useEffect(() => {
    adminApi
      .get("/Notifications", {
        signal: source.current.signal,
        params: {
          filter: {
            isRead: false
          },
          pagination: {
            pageIndex: 1,
            pageSize: 5
          }
        }
      })
      .then(data => setNotifications(data.records))
      .catch(error => console.log(error));

    adminApi
      .get("/Notifications/UnreadCount", {
        signal: source.current.signal
      })
      .then(data => setUnread(data))
      .catch(error => console.log(error));
  }, [reloadNotification]);

  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const markAsRead = id => {
    adminApi
      .post(
        "/Notifications/Read",
        {
          id: id
        },
        {
          signal: source.current.signal
        }
      )
      .catch(error => console.log(error))
      .finally(() => setOpenNotification(null));
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogOut = () => {
    localStorage.removeItem("Authorization");
    window.location.href = "/auth/login";
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Profile"
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.headerLinksSvg + " " + classes.links} />
          <b>{token["id"]}</b>
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              Profile
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem onClick={handleLogOut} className={dropdownItem}>
                      Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <NotificationIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          {unread > 0 && (
            <span className={classes.notifications}>{unread}</span>
          )}
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {notifications.length === 0 ? (
                      <MenuItem
                        key={0}
                        className={dropdownItem}
                        onClick={handleCloseNotification}
                      >
                        Yay, You have no notifications.
                      </MenuItem>
                    ) : (
                      notifications.map(notification => {
                        let notif = GetNotification(notification);
                        return (
                          <Link key={notification.id} to={notif.link}>
                            <MenuItem
                              onClick={() => markAsRead(notification.id)}
                              className={dropdownItem}
                            >
                              {notif.content}
                              <br />
                              <div>
                                {moment(notification.time + "Z").fromNow()}
                              </div>
                            </MenuItem>
                          </Link>
                        );
                      })
                    )}
                    <Link key={1} to={"/admin/MyNotification"}>
                      <MenuItem
                        onClick={handleCloseNotification}
                        className={dropdownItem}
                      >
                        See All
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

NavbarLinks.propTypes = {
  rtlActive: PropTypes.bool
};
