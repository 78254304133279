import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewTopUp from "./ViewTopUp";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { creditMonitoringApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function ApproveTopUp(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id } = useParams();
  const [topUp, setTopUp] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    creditMonitoringApi
      .get("/TopUps/" + id, {
        signal: source.current.signal
      })
      .then(data => setTopUp(data))
      .catch(error => console.log(error));
  }, [id]);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    creditMonitoringApi
      .post(
        "/TopUps/Approve",
        {
          id: id
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/TopUps/Pending" />
  ) : (
    topUp !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>APPROVE TOP UP</h4>
            </CardHeader>
            <CardBody>
              <p>
                <i>
                  *Please review and make sure the amount is correct before
                  approve the top up. Once it is done, it cannot be reversed and
                  amount will be added to the Agent&apos;s Balance.
                </i>
              </p>
              <form onSubmit={handleSubmit}>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Approve
                </Button>
              </form>
              <ViewTopUp topUp={topUp} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

ApproveTopUp.propTypes = {
  history: PropTypes.object
};
