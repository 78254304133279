import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { creditMonitoringApi } from "functions/apis";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { formatCurrency, formatDateTime } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function AgentBalanceHistory(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id, billingCurrency } = props;

  const [balanceHistories, setBalanceHistories] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    creditMonitoringApi
      .get("/Agents/" + id + "/BalanceHistories", {
        signal: source.current.signal,
        params: {
          pagination: pagination
        }
      })
      .then(data => {
        setBalanceHistories(
          data.records.map(record => {
            return {
              ...record,
              key: record.id
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, pagination]);

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>BALANCE HISTORIES</h4>
      </CardHeader>
      <CardBody>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={balanceHistories}
          filterable={false}
          columns={[
            {
              id: "mutationTime",
              Header: "Transaction Time",
              accessor: d => formatDateTime(new Date(d.mutationTime + "Z"))
            },
            {
              id: "mutationBalance",
              Header: "Transaction Amount",
              accessor: d =>
                formatCurrency(
                  billingCurrency === null ? "XXX" : billingCurrency.code,
                  d.mutationBalance
                )
            },
            {
              id: "currentBalance",
              Header: "Current Balance",
              accessor: d =>
                formatCurrency(
                  billingCurrency === null ? "XXX" : billingCurrency.code,
                  d.currentBalance
                )
            },
            {
              Header: "Description",
              headerStyle: { textAlign: "left" },
              accessor: "description"
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

AgentBalanceHistory.propTypes = {
  id: PropTypes.string,
  billingCurrency: PropTypes.object
};
