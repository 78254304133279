import MoneyIcon from "@material-ui/icons/Money";
import ExchangeRateList from "views/ExchangeRate/ExchangeRateList";
import CreateExchangeRate from "views/ExchangeRate/CreateExchangeRate.js";
import DeleteExchangeRate from "views/ExchangeRate/DeleteExchangeRate.js";

import { token } from "functions/Helper";

const exchangeRate = () => {
  let views = [];
  if (
    token["master.currency_exchange_rate.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/ExchangeRateList",
      name: "Exchange Rates",
      icon: MoneyIcon,
      component: ExchangeRateList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const exchangeRateHidden = () => {
  let views = [];
  if (
    token["master.currency_exchange_rate.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/ExchangeRate/Create",
        name: "Create ExchangeRate",
        component: CreateExchangeRate,
        layout: "/admin"
      },
      {
        path: "/ExchangeRate/Delete",
        name: "Delete ExchangeRate",
        component: DeleteExchangeRate,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "ExchangeRate Hidden",
        state: "exchangeRateHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { exchangeRate, exchangeRateHidden };
