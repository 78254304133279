import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

import { agentApi, creditMonitoringApi, voucherApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateVoid(props) {
  const source = useRef(new AbortController());

  const [agentList, setAgentList] = React.useState([]);
  const [agentCredit, setAgentCredit] = React.useState(null);

  useEffect(() => {
    if (token["agent.agents.read"] === "allowed" || token.is_admin === "True")
      agentApi
        .get("/Agents", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 0
            }
          }
        })
        .then(data => setAgentList(data.records))
        .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);
  const handleAgentIDChanged = (e, v) => {
    if (v === null) {
      setState({
        ...state,
        agentID: null
      });
      return;
    }
    if (state.voucherTypeID !== v.id)
      setState({
        ...state,
        agentID: v.id
      });
    creditMonitoringApi
      .get("/Agents/" + v.id, {
        signal: source.current.signal
      })
      .then(data => setAgentCredit(data))
      .catch(error => console.log(error));
  };

  const handleNoteChanged = e =>
    setState({ ...state, note: e.target.value === "" ? null : e.target.value });

  const handleRefundChanged = e =>
    setState({ ...state, refund: e.target.checked });

  const handleAmountChanged = e => {
    let newAmount = parseFloat(e.target.value === "" ? 0 : e.target.value);
    setState({ ...state, amount: newAmount });
  };

  const [state, setState] = React.useState({
    refund: false,
    agentID: null,
    amount: 0,
    note: null
  });

  const classes = useStyles();

  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    voucherApi
      .post("/Voids", state, {
        signal: source.current.signal
      })
      .then(data =>
        setRedirection({
          redirect: true,
          id: data
        })
      )
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirection.redirect ? (
    <Redirect to={"/admin/Void/Detail/" + redirection.id} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE VOID</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNoteChanged,
                  value: state.note === null ? "" : state.note
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleRefundChanged}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Refund"
              />
              <br />
              {state.refund && (
                <>
                  <Autocomplete
                    id="agent"
                    onChange={handleAgentIDChanged}
                    options={agentList}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    renderOption={option => (
                      <React.Fragment>
                        <b>{option.code}</b> - {option.name}
                      </React.Fragment>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Agent"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps
                        }}
                      />
                    )}
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Billing Currency"
                        disabled
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            agentCredit === null ||
                            agentCredit.billingCurrency === null
                              ? ""
                              : agentCredit.billingCurrency.code
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Amount"
                        id="numAmount"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          onChange: handleAmountChanged,
                          value: state.amount === 0 ? "" : state.amount
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </>
              )}
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateVoid.propTypes = {
  history: PropTypes.object
};
