import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { masterApi } from "functions/apis";

import { token } from "functions/Helper";
import { travelApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateMerchant(props) {
  const source = useRef(new AbortController());

  const [businessCategoryList, setBusinessCategoryList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [currencyList, setCurrencyList] = React.useState([]);

  useEffect(() => {
    if (
      token["master.business_categories.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/BusinessCategories", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setBusinessCategoryList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.countries.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Countries", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setCountryList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);

  useEffect(() => {
    masterApi
      .get("/Currencies", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));
  }, []);

  const [state, setState] = React.useState({});

  const handleCodeChanged = e =>
    setState({ ...state, code: e.target.value === "" ? null : e.target.value });
  const handleNameChanged = e =>
    setState({ ...state, name: e.target.value === "" ? null : e.target.value });
  const handleBusinessCategoryIDChanged = e =>
    setState({
      ...state,
      businessCategoryID: e.target.value === "" ? null : e.target.value
    });
  const handleCountryIDChanged = e =>
    setState({
      ...state,
      countryID: e.target.value === "" ? null : e.target.value
    });
  const handleEmailChanged = e =>
    setState({
      ...state,
      email: e.target.value === "" ? null : e.target.value
    });
  const handleContactNumberChanged = e =>
    setState({
      ...state,
      contactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleCompanyAddressChanged = e =>
    setState({
      ...state,
      companyAddress: e.target.value === "" ? null : e.target.value
    });
  const handleCompanyContactNumberChanged = e =>
    setState({
      ...state,
      companyContactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleCurrencyIDChanged = e => {
    setState({ ...state, settlementCurrencyID: e.target.value });
  };

  const handleBankNameChanged = e =>
    setState({ ...state, bankName: e.target.value });

  const handleBankBranchNameChanged = e =>
    setState({ ...state, bankBranchName: e.target.value });

  const handleBankAccountNoChanged = e =>
    setState({ ...state, bankAccountNo: e.target.value });

  const handleBankAccountNameChanged = e =>
    setState({ ...state, bankAccountName: e.target.value });

  const handleBankSwiftCodeChanged = e =>
    setState({ ...state, bankSwiftCode: e.target.value });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    travelApi
      .post("/Merchants", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/Merchants/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE MERCHANT</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleCodeChanged,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: state.name === null ? "" : state.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleEmailChanged,
                  value: state.email === null ? "" : state.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selBusinessCategory"
                      className={classes.selectLabel}
                    >
                      Business Category
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        state.businessCategoryID === null
                          ? ""
                          : state.businessCategoryID
                      }
                      onChange={handleBusinessCategoryIDChanged}
                    >
                      {businessCategoryList.map(businessCategory => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={businessCategory.id}
                            key={businessCategory.id}
                          >
                            <b>{businessCategory.code}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selLocation"
                      className={classes.selectLabel}
                    >
                      Location
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={state.countryID === null ? "" : state.countryID}
                      onChange={handleCountryIDChanged}
                    >
                      {countryList.map(country => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={country.id}
                            key={country.id}
                          >
                            <b>{country.name}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Person"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleContactNumberChanged,

                      value:
                        state.contactNumber === null ? "" : state.contactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleCompanyAddressChanged,
                      value:
                        state.companyAddress === null
                          ? ""
                          : state.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Company)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleCompanyContactNumberChanged,

                      value:
                        state.companyContactNumber === null
                          ? ""
                          : state.companyContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selCurrency"
                      className={classes.selectLabel}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        state.settlementCurrencyID === null
                          ? ""
                          : state.settlementCurrencyID
                      }
                      onChange={handleCurrencyIDChanged}
                    >
                      {currencyList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{record.code}</b>
                            <br />
                            {record.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Bank Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBankNameChanged,
                      value: state.bankName === null ? "" : state.bankName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Bank Branch Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBankBranchNameChanged,
                      value:
                        state.bankBranchName === null
                          ? ""
                          : state.bankBranchName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Bank Account No"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBankAccountNoChanged,

                      value:
                        state.bankAccountNo === null ? "" : state.bankAccountNo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Bank Account Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBankAccountNameChanged,
                      value:
                        state.bankAccountName === null
                          ? ""
                          : state.bankAccountName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Bank Swift Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBankSwiftCodeChanged,
                      value:
                        state.bankSwiftCode === null ? "" : state.bankSwiftCode
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateMerchant.propTypes = {
  history: PropTypes.object
};
