import { Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { masterApi } from "functions/apis";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import moment from "moment";
import { tripDateFormat } from "functions/Helper";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

const useStyles = makeStyles(styles);

const defaultState = {
  promoCode: "",
  effectiveDate: null,
  expiryDate: null,
  currencyID: null,
  discountAmount: null,
  limit: null,
  minimalTrip: null,
  hasSectorRestriction: false,
  hasUserRestriction: false,
  userEmail: null,
  allowedSectorIDs: []
};

export default function DeleteOnlineBookingsPromoCode(props) {
  const [redirect, setRedirect] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [state, setState] = useState({ ...defaultState });
  const [currencyList, setCurrencyList] = React.useState([]);

  const { id } = useParams();

  const deleteOnlineBookingPromoCodeSignal = React.useRef(
    new AbortController()
  );

  const getSector = () => {
    masterApi
      .get("/Sectors", {
        signal: deleteOnlineBookingPromoCodeSignal.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => {
        setSectorList(data.records);
      })
      .catch(error => console.log(error));
  };

  const getCurrency = () => {
    masterApi
      .get("/Currencies", {
        signal: deleteOnlineBookingPromoCodeSignal.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));
  };

  const getData = () => {
    bookingApi
      .get("OnlineBookingPromoCodes/" + id, {
        signal: deleteOnlineBookingPromoCodeSignal.current.signal,
        params: {
          ID: id
        }
      })
      .then(data => {
        setState({
          ...state,
          currencyID: data.currency.id,
          discountAmount: data.discountAmount,
          effectiveDate: moment(new Date(data.effectiveDate + "Z")),
          expiryDate: moment(new Date(data.expiryDate + "Z")),
          hasSectorRestriction: data.hasSectorRestriction,
          hasUserRestriction: data.hasUserRestriction,
          limit: data.limit,
          minimalTrip: data.minimalTrip,
          promoCode: data.promoCode,
          userEmail: data.userEmail
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getSector();
    getCurrency();
    getData();
    return () => {
      deleteOnlineBookingPromoCodeSignal.current.abort();
    };
  }, []);

  const onPressDelete = () => {
    bookingApi
      .delete("OnlineBookingPromoCodes/" + id, {
        signal: deleteOnlineBookingPromoCodeSignal.current.signal,
        params: {
          ID: id
        }
      })
      .then(() => {
        setRedirect(true);
      })
      .catch(error => console.log(error));
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/OnlineBookingPromoCodesList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              DELETE ONLINE BOOKING PROMO CODES
            </h4>
          </CardHeader>
          <CardBody>
            <p>Delete this Promo Code?</p>
            <Button onClick={() => props.history.goBack()} value="Back">
              CANCEL
            </Button>
            <Button
              onClick={() => onPressDelete()}
              color="rose"
              type="submit"
              value="Submit"
            >
              DELETE
            </Button>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Promo Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: state.promoCode,
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Discount Amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    type: "number",
                    value: state.discountAmount ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Minimal Trip"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: state.minimalTrip ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Limit"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: state.limit ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <InputLabel className={classes.selectLabel}>
                  Effective Date
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.effectiveDate}
                    onChange={e =>
                      setState({
                        ...state,
                        EffectiveDate: e === "" ? null : e
                      })
                    }
                    dateFormat={tripDateFormat}
                    timeFormat={true}
                    disabled
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Effective Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <InputLabel className={classes.selectLabel}>
                  Expiry Date
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.expiryDate}
                    disabled
                    dateFormat={tripDateFormat}
                    timeFormat={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Effective Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    Currency
                  </InputLabel>
                  <Select
                    disabled
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.currencyID ?? ""}
                  >
                    {currencyList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <b>{record.code}</b>
                          <br />
                          {record.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={state.hasUserRestriction}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      disabled
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has User Restriction"
                />
              </GridItem>
              {state.hasUserRestriction && (
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="User Email"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: state.userEmail,
                      disabled: true
                    }}
                  />
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      disabled
                      checked={state.hasSectorRestriction}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Route Restriction"
                />
              </GridItem>
              {state.hasSectorRestriction && (
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAllowedRoutes"
                      className={classes.selectLabel}
                    >
                      Allowed Routes
                    </InputLabel>
                    <Select
                      multiple
                      disabled
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={state.allowedSectorIDs}
                      renderValue={() => (
                        <div className={classes.chips}>
                          {state.allowedSectorIDs.map(value => {
                            let sector = sectorList.find(
                              item => item.id === value
                            );
                            return (
                              <div key={value} className={classes.chips}>
                                <Chip
                                  key={sector.id}
                                  label={<b>{sector.name}</b>}
                                  className={classes.chip}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    >
                      {sectorList.map(record => {
                        let sector = sectorList.find(
                          item => item.id === record.id
                        );
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{sector.code}</b> <br />
                            {sector.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DeleteOnlineBookingsPromoCode.propTypes = {
  history: PropTypes.object
};
