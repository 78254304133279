import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";
import CardIcon from "components/Card/CardIcon.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import PublicationStatus from "enums/PublicationStatus";
import { bookingApi } from "functions/apis";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function BookingTypeDetail(props) {
  const source = useRef(new AbortController());

  let { id } = useParams();
  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const [bookingType, setBookingType] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    bookingApi
      .get("/BookingTypes/" + id, {
        signal: source.current.signal
      })
      .then(data => setBookingType(data))
      .catch(error => console.log(error));
  }, [id, classes]);

  return (
    bookingType !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>BOOKING TYPE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <p>
                <i>
                  *Booking Type status is &apos;
                  {PublicationStatus(bookingType.publicationStatus)}&apos;.
                </i>
              </p>
              {bookingType.publicationStatus === 0 ? (
                <>
                  {(token["booking.booking_types.publish"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/BookingType/Publish/" + bookingType.id}>
                      <Button color="rose" value="Publish">
                        PUBLISH
                      </Button>
                    </Link>
                  )}
                  {(token["booking.booking_types.write"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/BookingType/Delete/" + bookingType.id}>
                      <Button color="rose" value="Delete">
                        DELETE
                      </Button>
                    </Link>
                  )}
                  {(token["booking.booking_types.write"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/BookingType/Edit/" + id}>
                      <Button color="rose" type="submit" value="Edit">
                        EDIT
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <p>
                  <i>
                    *Only &apos;InDraft&apos; Booking Type can be edited,
                    published or deleted.
                  </i>
                </p>
              )}
              <CustomInput
                labelText="Code"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: bookingType.code
                }}
              />
              <CustomInput
                labelText="Name"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: bookingType.name
                }}
              />
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={bookingType.isRoundTrip}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Is Round Trip"
              />
              <CustomInput
                labelText="Departure Sector"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: bookingType.departureSector.code
                }}
              />
              <div className={classes.checkboxAndRadio}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={bookingType.hasPaxTypeRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has PaxType Restriction"
                      />
                    </GridItem>
                    {bookingType.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
                      <GridItem>
                        <CustomInput
                          disabled
                          labelText="Allowed PaxType"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: bookingType.allowedPaxType.name
                          }}
                        />
                      </GridItem>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={bookingType.hasNationalityRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has Nationality Restriction"
                      />
                    </GridItem>
                    {bookingType.hasNationalityRestriction && ( // render only if Has PaxType Restriction
                      <GridItem>
                        <CustomInput
                          disabled
                          labelText="Allowed Nationality"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: bookingType.allowedNationality.nationality
                          }}
                        />
                      </GridItem>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={bookingType.hasDayGroupRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has DayGroup Restriction"
                      />
                    </GridItem>
                    {bookingType.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                      <GridItem>
                        <CustomInput
                          disabled
                          labelText="Allowed DayGroup"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: bookingType.allowedDayGroup.name
                          }}
                        />
                      </GridItem>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
              <CustomInput
                labelText="Additional Criteria String"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: bookingType.additionalCriteriaString
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

BookingTypeDetail.propTypes = {
  history: PropTypes.object
};
