import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";

import InDraftList from "views/FeePolicyGroup/InDraftList.js";
import PublishedList from "views/FeePolicyGroup/PublishedList.js";
import DeactivatedList from "views/FeePolicyGroup/DeactivatedList.js";
import Create from "views/FeePolicyGroup/Create.js";
import Edit from "views/FeePolicyGroup/Edit.js";
import Detail from "views/FeePolicyGroup/Detail.js";
import Delete from "views/FeePolicyGroup/Delete.js";
import Publish from "views/FeePolicyGroup/Publish.js";
import Deactivate from "views/FeePolicyGroup/Deactivate.js";

import { token } from "functions/Helper";

const feePolicyGroup = () => {
  let views = [];
  if (
    token["booking.fee_policy_groups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/FeePolicyGroups/InDraft",
        name: "In Draft",
        mini: "IN",
        component: InDraftList,
        layout: "/admin"
      },
      {
        path: "/FeePolicyGroups/Published",
        name: "Published",
        mini: "PU",
        component: PublishedList,
        layout: "/admin"
      },
      {
        path: "/FeePolicyGroups/Deactivated",
        name: "Deactivated",
        mini: "DE",
        component: DeactivatedList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Fee Policy Groups",
        icon: AirlineSeatReclineNormalIcon,
        state: "feePolicyGroupCollapse",
        views: views
      }
    ];
  return [];
};

const feePolicyGroupHidden = () => {
  let views = [];
  if (
    token["booking.fee_policy_groups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/FeePolicyGroup/Detail",
      name: "Fee Policy Group",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.fee_policy_groups.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/FeePolicyGroup/Create",
        name: "Create Fee Policy Group",
        component: Create,
        layout: "/admin"
      },
      {
        path: "/FeePolicyGroup/Edit",
        name: "Edit Fee Policy Group",
        component: Edit,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/FeePolicyGroup/Delete",
        name: "Delete Fee policy Group",
        component: Delete,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.fee_policy_groups.publish"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/FeePolicyGroup/Publish",
      name: "Publish Fee Policy Group",
      component: Publish,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.fee_policy_groups.deactivate"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/FeePolicyGroup/Deactivate",
      name: "Deactivate Fee Policy Group",
      component: Deactivate,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "FeePolicyGroup Hidden",
        state: "feePolicyGroupHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { feePolicyGroup, feePolicyGroupHidden };
