import LoyaltyIcon from "@material-ui/icons/Loyalty";

import InDraftVoucherTypeList from "views/VoucherType/InDraftVoucherTypeList.js";
import PublishedVoucherTypeList from "views/VoucherType/PublishedVoucherTypeList.js";
import DeactivatedVoucherTypeList from "views/VoucherType/DeactivatedVoucherTypeList.js";
import CreateVoucherType from "views/VoucherType/CreateVoucherType.js";
import EditVoucherType from "views/VoucherType/EditVoucherType.js";
import VoucherTypeDetail from "views/VoucherType/VoucherTypeDetail.js";
import DeleteVoucherType from "views/VoucherType/DeleteVoucherType.js";
import PublishVoucherType from "views/VoucherType/PublishVoucherType.js";
import DeactivateVoucherType from "views/VoucherType/DeactivateVoucherType.js";

import { token } from "functions/Helper";

const voucherType = () => {
  let views = [];
  if (
    token["voucher.voucher_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/VoucherTypes/InDraft",
        name: "In Draft",
        mini: "IN",
        component: InDraftVoucherTypeList,
        layout: "/admin"
      },
      {
        path: "/VoucherTypes/Published",
        name: "Published",
        mini: "PU",
        component: PublishedVoucherTypeList,
        layout: "/admin"
      },
      {
        path: "/VoucherTypes/Deactivated",
        name: "Deactivated",
        mini: "DE",
        component: DeactivatedVoucherTypeList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Voucher Types",
        icon: LoyaltyIcon,
        state: "voucherTypeCollapse",
        views: views
      }
    ];
  return [];
};

const voucherTypeHidden = () => {
  let views = [];
  if (
    token["voucher.voucher_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/VoucherType/Detail",
      name: "Voucher Type",
      component: VoucherTypeDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["voucher.voucher_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/VoucherType/Create",
        name: "Create Voucher Type",
        component: CreateVoucherType,
        layout: "/admin"
      },
      {
        path: "/VoucherType/Edit",
        name: "Edit Voucher Type",
        component: EditVoucherType,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/VoucherType/Delete",
        name: "Delete Voucher Type",
        component: DeleteVoucherType,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["voucher.voucher_types.publish"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/VoucherType/Publish",
      name: "Publish Voucher Type",
      component: PublishVoucherType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["voucher.voucher_types.deactivate"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/VoucherType/Deactivate",
      name: "Deactivate Voucher Type",
      component: DeactivateVoucherType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "VoucherType Hidden",
        state: "voucherTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { voucherType, voucherTypeHidden };
