import ReceiptIcon from "@material-ui/icons/Receipt";

import PendingTopUpList from "views/TopUp/PendingTopUpList.js";
import ApprovedTopUpList from "views/TopUp/ApprovedTopUpList.js";
import RejectedTopUpList from "views/TopUp/RejectedTopUpList.js";
import TopUpDetail from "views/TopUp/TopUpDetail";
import ApproveTopUp from "views/TopUp/ApproveTopUp";
import RejectTopUp from "views/TopUp/RejectTopUp";

import { token } from "functions/Helper";

const topUp = () => {
  let views = [];
  if (
    token["creditmonitoring.top_ups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/TopUps/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingTopUpList,
        layout: "/admin"
      },
      {
        path: "/TopUps/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedTopUpList,
        layout: "/admin"
      },
      {
        path: "/TopUps/Rejected",
        name: "Rejected",
        mini: "RE",
        component: RejectedTopUpList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Top Ups",
        icon: ReceiptIcon,
        state: "topUpCollapse",
        views: views
      }
    ];
  return [];
};

const topUpHidden = () => {
  let views = [];
  if (
    token["creditmonitoring.top_ups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/TopUps/Detail",
      name: "TopUp Detail",
      component: TopUpDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["creditmonitoring.top_ups.approve"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/TopUps/Approve",
      name: "Approve Top Up",
      component: ApproveTopUp,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["creditmonitoring.top_ups.reject"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/TopUps/Reject",
      name: "Reject Top Up",
      component: RejectTopUp,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "TopUp Hidden",
        state: "topUpHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { topUp, topUpHidden };
