import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CreateMerchantInventoryControlGroup from "views/MerchantInventoryControlGroup/CreateMerchantInventoryControlGroup";
import DeleteMerchantInventoryControlGroup from "views/MerchantInventoryControlGroup/DeleteMerchantInventoryControlGroup";
import MerchantInventoryControlGroupList from "views/MerchantInventoryControlGroup/MerchantInventoryControlGroupList";
import ViewMerchantInventoryControlGroup from "views/MerchantInventoryControlGroup/ViewMerchantInventoryControlGroup";

const merchantInventoryControlGroup = () => {
  let views = [];

  views.push({
    path: "/MerchantInventoryControlGroupList",
    name: "M Inventory Control Group",
    icon: ConfirmationNumberIcon,
    component: MerchantInventoryControlGroupList,
    layout: "/admin"
  });

  return views;
};

const merchantInventoryControlGroupHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantInventoryControlGroup/Create",
      name: "Create Merchant Inventory Control Group",
      component: CreateMerchantInventoryControlGroup,
      layout: "/admin"
    },
    {
      path: "/MerchantInventoryControlGroup/View",
      name: "View Merchant Inventory Control Group",
      component: ViewMerchantInventoryControlGroup,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantInventoryControlGroup/Delete",
      name: "Delete Merchant Inventory Control Group",
      component: DeleteMerchantInventoryControlGroup,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchant Inventory Control Group Hidden",
      state: "merchantInventoryControlGroupHidden",
      hideInSidebar: true,
      views
    }
  ];
};

export { merchantInventoryControlGroup, merchantInventoryControlGroupHidden };
