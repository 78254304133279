import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { GetVoucherTypeValidityTypeDescription } from "enums/VoucherTypeValidityType";
import RichTextComponent from "views/MerchantPromo/SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ViewVoucherType(props) {
  const { voucherType } = props;
  const classes = useStyles();
  return (
    <>
      <CustomInput
        labelText="Code"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: voucherType.code
        }}
      />

      <CustomInput
        labelText="Name"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: voucherType.name
        }}
      />
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <CustomInput
            disabled
            labelText="Validity"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              type: "number",
              value: voucherType.validity
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            disabled
            labelText="Validity Type"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: GetVoucherTypeValidityTypeDescription(
                voucherType.validityType
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <InputLabel htmlFor="selValidityType" className={classes.selectLabel}>
            Information
          </InputLabel>
        </GridItem>
        <GridItem xs={12} md={12}>
          <div
            style={{
              borderBottom: "1px solid #d6d6d6",
              marginTop: 12
            }}
          >
            <RichTextComponent
              onChange={() => {}}
              disable={true}
              value={
                voucherType.information != null
                  ? deserializeHtmlString(voucherType.information)
                  : null
              }
            />
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.checkboxAndRadio}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={voucherType.includeSurcharge}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Include Surcharge"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={voucherType.includePDF}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Include PDF"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={voucherType.includeTF}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Include TF"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={voucherType.includeCF}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Include CF"
            />
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.checkboxAndRadio}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={voucherType.hasPaxTypeRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has PaxType Restriction"
              />
            </GridItem>
            {voucherType.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
              <GridItem>
                <CustomInput
                  labelText="Allowed PaxType"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.allowedPaxType.name
                  }}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem xs={12} sm={6}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={voucherType.hasNationalityRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has Nationality Restriction"
              />
            </GridItem>
            {voucherType.hasNationalityRestriction && ( // render only if Has PaxType Restriction
              <GridItem>
                <CustomInput
                  labelText="Allowed Nationality"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.allowedNationality.nationality
                  }}
                />
              </GridItem>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.checkboxAndRadio}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={voucherType.hasIdentificationNoRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has IdentificationNo Restriction"
              />
            </GridItem>
            {voucherType.hasIdentificationNoRestriction && (
              <GridItem>
                <CustomInput
                  disabled
                  labelText="MaxAllowedIdentificationNoCount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.maxAllowedIdentificationNoCount
                  }}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem xs={12} sm={6}>
            <GridItem>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={voucherType.hasMinimumIssuanceQuantityRestriction}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has Minimum Issuance Quantity Restriction"
              />
            </GridItem>
            {voucherType.hasMinimumIssuanceQuantityRestriction && (
              <GridItem>
                <CustomInput
                  disabled
                  labelText="Minimum Issuance Quantity"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.minimumIssuanceQuantity
                  }}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridItem>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={voucherType.hasMerchant}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has Merchant"
              />
            </GridItem>
            {voucherType.hasMerchant && (
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Merchant"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.merchant.name
                  }}
                />
              </GridItem>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <h4 className={classes.cardIconTitle}>TRIPS: </h4>
      <GridContainer>
        {voucherType.trips.map(trip => {
          return (
            <GridItem md={6} lg={6} key={trip.id}>
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>{trip.description}</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Sequence"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: trip.sequence
                    }}
                  />
                  <CustomInput
                    labelText="Base Value"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: "SGD" + " " + trip.baseValue
                    }}
                  />
                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.nonTicket}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Non Ticket"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.hasGST}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has GST"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.isVTL}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Is VTL"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.hasDayGroupRestriction}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has DayGroup Restriction"
                    />
                  </GridItem>
                  {trip.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                    <GridItem>
                      <CustomInput
                        labelText="Allowed DayGroup"
                        disabled
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: trip.allowedDayGroup.name
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.hasSectorRestriction}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Sector Restriction"
                    />
                  </GridItem>
                  {trip.hasSectorRestriction && ( // render only if Has Sector Restriction
                    <GridItem>
                      <CustomInput
                        labelText="Allowed Sector"
                        disabled
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: trip.allowedSector.name
                        }}
                      />
                    </GridItem>
                  )}

                  <GridItem>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={trip.hasRouteRestriction}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Route Restriction"
                    />
                  </GridItem>
                  {trip.hasRouteRestriction && ( // render only if Has Route Restriction
                    <GridItem>
                      <p>Allowed Routes: </p>
                      {trip.allowedRoutes.map(allowedRoute => {
                        return (
                          <MenuItem
                            key={allowedRoute.route.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={allowedRoute.id}
                          >
                            <b>{allowedRoute.route.code}</b>
                            <p>{allowedRoute.route.name}</p>
                          </MenuItem>
                        );
                      })}
                    </GridItem>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </>
  );
}

ViewVoucherType.propTypes = {
  voucherType: PropTypes.object
};
