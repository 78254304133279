import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import CardIcon from "components/Card/CardIcon.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import PublicationStatus from "enums/PublicationStatus";
import { bookingApi } from "functions/apis";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function Detail(props) {
  const source = useRef(new AbortController());

  let { id } = useParams();
  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const [feePolicyGroup, setFeePolicyGroup] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    bookingApi
      .get("/FeePolicyGroups/" + id, {
        signal: source.current.signal
      })
      .then(data => setFeePolicyGroup(data))
      .catch(error => console.log(error));
  }, [id, classes]);

  return (
    feePolicyGroup !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>FEE POLICY GROUP</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <p>
                <i>
                  *FeePolicyGroup status is &apos;
                  {PublicationStatus(feePolicyGroup.publicationStatus)}&apos;.
                </i>
              </p>
              {feePolicyGroup.publicationStatus === 0 ? (
                <>
                  {(token["booking.fee_policy_groups.publish"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link
                      to={"/admin/FeePolicyGroup/Publish/" + feePolicyGroup.id}
                    >
                      <Button color="rose" value="Publish">
                        PUBLISH
                      </Button>
                    </Link>
                  )}
                  {(token["booking.fee_policy_groups.write"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link
                      to={"/admin/FeePolicyGroup/Delete/" + feePolicyGroup.id}
                    >
                      <Button color="rose" value="Delete">
                        DELETE
                      </Button>
                    </Link>
                  )}
                  {(token["booking.fee_policy_groups.write"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/FeePolicyGroup/Edit/" + id}>
                      <Button color="rose" type="submit" value="Edit">
                        EDIT
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <p>
                  <i>
                    *Only &apos;InDraft&apos; FeePolicyGroup can be edited,
                    published or deleted.
                  </i>
                </p>
              )}
              <CustomInput
                labelText="Code"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: feePolicyGroup.code
                }}
              />
              <CustomInput
                labelText="Name"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: feePolicyGroup.name
                }}
              />
              <CustomInput
                labelText="Common Fee Policy"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: feePolicyGroup.commonFeePolicy.code
                }}
              />
              <CustomInput
                labelText="PeakHour Fee Policy"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: feePolicyGroup.peakHourFeePolicy.code
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
