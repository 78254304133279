import moment from "moment";
import { jsx } from "slate-hyperscript";

export const tripDateFormat = "dddd, MMMM Do YYYY";
export const dateFormat = "MMMM Do YYYY";

export function formatCurrency(currencyCode, number) {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode
  });
  return formatter.format(number);
}

export function formatDateTime(dateTime) {
  return moment(dateTime).format("YYYY MMM DD, h:mm:ss a");
}

export function formatDate(dateTime) {
  return moment(dateTime).format(dateFormat);
}

export function formatTripDate(dateTime) {
  return moment(dateTime).format(tripDateFormat);
}

export function makeSGTime(date) {
  let activeDate = new Date(date);
  activeDate.setHours(
    activeDate.getHours() - 8 + activeDate.getTimezoneOffset() / 60
  );
  return activeDate;
}

function deserialize(el, markAttributes = {}) {
  if (el.nodeType === Node.TEXT_NODE) {
    // console.log("disini test");
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  // console.log("value el: ", el);

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "I":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  let align = "";

  if (el.style.textAlign) {
    align = el.style.textAlign;
  }
  switch (el.nodeName) {
    case "BODY":
      // console.log("masuk body", children);
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE": {
      if (align != "") {
        return jsx("element", { type: "quote", align: align }, children);
      }
      return jsx("element", { type: "quote" }, children);
    }
    case "OL": {
      return jsx("element", { type: "numbered-list" }, children);
    }
    case "UL": {
      return jsx("element", { type: "bulleted-list" }, children);
    }
    case "P": {
      if (align != "") {
        return jsx("element", { type: "paragraph", align: align }, children);
      }
      return jsx("element", { type: "paragraph" }, children);
    }
    case "H1": {
      if (align != "") {
        return jsx("element", { type: "heading-one", align: align }, children);
      }
      return jsx("element", { type: "heading-one" }, children);
    }
    case "H2": {
      if (align != "") {
        return jsx("element", { type: "heading-two", align: align }, children);
      }
      return jsx("element", { type: "heading-two" }, children);
    }
    case "LI": {
      return jsx("element", { type: "list-item" }, children);
    }
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    default:
      return children;
  }
}

export function deserializeHtmlString(str) {
  const dataText = new DOMParser().parseFromString(str, "text/html");
  // console.log(dataText.body);

  const dataTxt = deserialize(dataText.body);

  // console.log("Hasil: ", dataText);

  return dataTxt;
}

export function makeUTC(date) {
  if (date === null) return null;
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

function parseJWT(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

const token =
  localStorage.getItem("Authorization") !== null
    ? parseJWT(localStorage.getItem("Authorization").replace("Bearer ", ""))
    : {};

export { token };
