export function GetPaymentProcessors() {
  return [
    {
      key: 0,
      description: "Stripe"
    },
    {
      key: 1,
      description: "Midtrans"
    }
  ];
}

export function GetPaymentProcessorDescription(value) {
  let paymentProcessor = GetPaymentProcessors().find(
    item => item.key === value
  );
  if (paymentProcessor !== undefined) return paymentProcessor.description;
  return value;
}
