import ExposureIcon from "@material-ui/icons/Exposure";
import AdjustmentList from "views/Adjustment/AdjustmentList.js";
import CreateAdjustment from "views/Adjustment/CreateAdjustment.js";

import { token } from "functions/Helper";

const adjustment = () => {
  let views = [];
  if (
    token["creditmonitoring.adjustments.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/AdjustmentList",
      name: "Adjustments",
      icon: ExposureIcon,
      component: AdjustmentList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const adjustmentHidden = () => {
  let views = [];
  if (
    token["creditmonitoring.adjustments.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Adjustment/Create",
      name: "Create Adjustment",
      component: CreateAdjustment,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Adjustment Hidden",
        state: "adjustmentHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { adjustment, adjustmentHidden };
