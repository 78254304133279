import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import OrderList from "views/Order/OrderList.js";
import OrderDetail from "views/Order/OrderDetail.js";
import CancelOrder from "views/Order/CancelOrder.js";
import RecalculateOrderGST from "views/Order/RecalculateOrderGST.js";
import BulkRecalculateOrderGST from "views/Order/BulkRecalculateOrderGST.js";

import { token } from "functions/Helper";

const order = () => {
  let views = [];
  if (token["order.orders.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/OrderList",
      name: "Orders",
      icon: MonetizationOnIcon,
      component: OrderList,
      layout: "/admin"
    });
  return views;
};

const orderHidden = () => {
  let views = [];
  if (token["order.orders.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Orders/Detail",
      name: "Order Detail",
      component: OrderDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["order.orders.cancel"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Orders/Cancel",
      name: "Cancel Order",
      component: CancelOrder,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["order.orders.recalculate_gst"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Orders/RecalculateGST",
      name: "Recalculate Order GST",
      component: RecalculateOrderGST,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["order.orders.bulk_recalculate_gst"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Orders/BulkRecalculateGST",
      name: "Bulk Recalculate Order GST",
      component: BulkRecalculateOrderGST,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Order Cancellation Hidden",
        state: "orderCancellationHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { order, orderHidden };
