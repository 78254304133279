import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Chip from "@material-ui/core/Chip";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { adminApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function UserRoleList(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  let { id } = props;
  const [reload, setReload] = React.useState({});
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    adminApi
      .get("/Users/" + id + "/Roles", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/Roles/Detail/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> View
                    </Button>
                  </Link>
                  <Button
                    color="danger"
                    className="remove"
                    onClick={() =>
                      adminApi
                        .delete("/Users/" + id + "/Roles/" + record.id, {
                          signal: source.current.signal
                        })
                        .then(() => setReload({}))
                        .catch(error => console.log(error))
                    }
                  >
                    <DeleteIcon /> DELETE
                  </Button>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, filter, pagination, reload]);

  const [availableRoles, setAvailableRoles] = React.useState([]);
  useEffect(() => {
    adminApi
      .get("/Users/" + id + "/Roles/availableRoles", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setAvailableRoles(data.records))
      .catch(error => console.log(error));
  }, [id, reload]);

  let defaultUserRoles = {
    roles: []
  };
  const [userRoles, setUserRoles] = React.useState(defaultUserRoles);

  const handleUserRolesChanged = e =>
    setUserRoles({ ...userRoles, roles: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();
    adminApi
      .post("/Users/" + id + "/Roles", userRoles, {
        signal: source.current.signal
      })
      .then(() => {
        setUserRoles(defaultUserRoles);
        setReload({});
      })
      .catch(error => console.log(error));
  };

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>ROLES</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={3}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selRoles"
                    className={classes.selectLabel}
                  >
                    Roles
                  </InputLabel>
                  <Select
                    multiple
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={userRoles.roles}
                    onChange={handleUserRolesChanged}
                    renderValue={() => (
                      <div className={classes.chips}>
                        {userRoles.roles.map(role => (
                          <div key={role} className={classes.chips}>
                            <Chip
                              key={role}
                              label={<b>{role}</b>}
                              className={classes.chip}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    {availableRoles.map(role => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={role.name}
                        key={role.name}
                      >
                        <b>{role.name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button color="rose" type="submit" value="Submit">
                  Add
                </Button>
              </form>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Name",
                  accessor: "name"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

UserRoleList.propTypes = {
  id: PropTypes.string
};
