import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";

import { token } from "functions/Helper";
import CancelErrorList from "views/CoreApiCheckIn/CancelErrorList";
import CancelledList from "views/CoreApiCheckIn/CancelledList";
import CheckedInList from "views/CoreApiCheckIn/CheckedInList";
import CheckInErrorList from "views/CoreApiCheckIn/CheckInErrorList";
import PendingList from "views/CoreApiCheckIn/PendingList";
import Detail from "views/CoreApiCheckIn/Detail";
import RetryProcess from "views/CoreApiCheckIn/RetryProcess";
import RetryCancel from "views/CoreApiCheckIn/RetryCancel";
import CancelResolved from "views/CoreApiCheckIn/CancelResolved";
import CheckInResolved from "views/CoreApiCheckIn/CheckInResolved";
import SetAsCheckInResolved from "views/CoreApiCheckIn/SetAsCheckInResolved";
import CheckIn from "views/CoreApiCheckIn/CheckIn";
import Cancel from "views/CoreApiCheckIn/Cancel";
import SetAsCancelResolved from "views/CoreApiCheckIn/SetAsCancelResolved";
import BulkRetryCancelError from "views/CoreApiCheckIn/BulkRetryCancelError";
import BulkRetryCheckInError from "views/CoreApiCheckIn/BulkRetryCheckInError ";

const coreApiCheckIn = () => {
  let views = [];
  if (
    token["booking.core_api_check_ins.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/CoreApiCheckIns/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingList,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/CheckedIn",
        name: "Checked In",
        mini: "CI",
        component: CheckedInList,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/Cancelled",
        name: "Cancelled",
        mini: "CC",
        component: CancelledList,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/CheckInError",
        name: "Check-In Error",
        mini: "CE",
        component: CheckInErrorList,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/CancelError",
        name: "Cancel Error",
        mini: "CA",
        component: CancelErrorList,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/CheckInResolved",
        name: "Check-In Resolved",
        mini: "IR",
        component: CheckInResolved,
        layout: "/admin"
      },
      {
        path: "/CoreApiCheckIns/CancelResolved",
        name: "Cancel Resolved",
        mini: "AR",
        component: CancelResolved,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Core Api Check Ins",
        icon: AirlineSeatReclineNormalIcon,
        state: "coreApiCheckInCollapse",
        views: views
      }
    ];
  return [];
};

const coreApiCheckInHidden = () => {
  let views = [];
  if (
    token["booking.core_api_check_ins.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/CoreApiCheckIn/Detail",
      name: "CoreApiCheckIn Detail",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.core_api_check_ins.retry_process"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/CoreApiCheckIn/RetryProcess",
        name: "CoreApiCheckIn Retry Process",
        component: RetryProcess,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/CoreApiCheckIn/BulkRetryCheckInError",
        name: "Bulk Retry CheckIn Error",
        component: BulkRetryCheckInError,
        layout: "/admin"
      }
    ]);
  if (
    token["booking.core_api_check_ins.retry_cancel"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/CoreApiCheckIn/RetryCancel",
        name: "CoreApiCheckIn Retry Cancel",
        component: RetryCancel,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/CoreApiCheckIn/BulkRetryCancelError",
        name: "Bulk Retry Cancel Error",
        component: BulkRetryCancelError,
        layout: "/admin"
      }
    ]);
  if (
    token["booking.core_api_check_ins.set_as_check_in_resolved"] ===
      "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/CoreApiCheckIn/SetAsCheckInResolved",
      name: "CoreApiCheckIn Set As Check-In Resolved",
      component: SetAsCheckInResolved,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.core_api_check_ins.set_as_cancel_resolved"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/CoreApiCheckIn/SetAsCancelResolved",
      name: "CoreApiCheckIn Set As Cancel Resolved",
      component: SetAsCancelResolved,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });

  if (
    token["booking.core_api_check_ins.cancel"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/CoreApiCheckIn/Cancel",
        name: "CoreApiCheckIn Cancel",
        component: Cancel,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.core_api_check_ins.check_in"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/CoreApiCheckIn/CheckIn",
        name: "CoreApiCheckIn Check In",
        component: CheckIn,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "CoreApiCheckIn Hidden",
        state: "coreApiCheckinHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { coreApiCheckIn, coreApiCheckInHidden };
