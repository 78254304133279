import { Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { masterApi } from "functions/apis";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { coreApi } from "functions/apis";
import moment from "moment";
import { tripDateFormat } from "functions/Helper";
import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

const defaultState = {
  date: null,
  hasRouteRestriction: false,
  routeID: null,
  hasTimeRestriction: false,
  time: null
};

export default function deletePeakHourTrips(props) {
  const [redirect, setRedirect] = useState(false);
  const [routeList, setRouteList] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [state, setState] = useState({ ...defaultState });
  const [selectedSectorID, setSelectedSectorID] = useState();
  const [disable, setDisable] = useState(false);

  const { id } = useParams();

  const deletePeakHourTripsSignal = React.useRef(new AbortController());

  useEffect(() => {
    console.log(id);
  }, [id]);

  const getSector = () => {
    masterApi
      .get("/Sectors", {
        signal: deletePeakHourTripsSignal.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => setSectorList(data.records))
      .catch(error => console.log(error));
  };

  const getRoutes = () => {
    masterApi
      .get("/Routes", {
        signal: deletePeakHourTripsSignal.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1,
            sectorID: selectedSectorID
          }
        }
      })
      .then(dataRoute => {
        setRouteList(dataRoute.records);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    if (selectedSectorID) {
      getRoutes();
    }
  }, [selectedSectorID]);

  const getData = () => {
    bookingApi
      .get("PeakHourTrips/" + id, {
        signal: deletePeakHourTripsSignal.current.signal,
        params: {
          ID: id
        }
      })
      .then(data => {
        setState({
          ...state,
          date: moment(new Date(data.date)),
          hasRouteRestriction: data.hasRouteRestriction,
          hasTimeRestriction: data.hasTimeRestriction,
          routeID: data.routeID,
          time: data.time
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData();
    return () => {
      deletePeakHourTripsSignal.current.abort();
    };
  }, []);

  useEffect(() => {
    if (state.routeID) {
      getRoutes();
      getSector();
    }
  }, [state]);

  useEffect(() => {
    if (sectorList.length > 0 && routeList.length > 0 && !selectedSectorID) {
      const tempSector = routeList.filter(x => x.id === state.routeID);

      if (tempSector.length > 0) {
        setSelectedSectorID(tempSector[0].sector?.id);
      }
    }
  }, [sectorList, routeList]);

  useEffect(() => {
    if (state.routeID !== null && state.date !== null) {
      let selectedRoute = routeList.find(r => r.id === state.routeID);
      if (selectedRoute) {
        let ports = selectedRoute.code.split(" - ");
        let embarkationPort = ports[0];
        let destinationPort = ports[1];
        if (embarkationPort === "TMFT") embarkationPort = "TMF";
        if (destinationPort === "TMFT") destinationPort = "TMF";
        coreApi
          .get("/Trips", {
            signal: deletePeakHourTripsSignal.current.signal,
            params: {
              embarkation: embarkationPort,
              destination: destinationPort,
              tripDate: moment(state.date).format("yyyyMMDD"),
              status: "active"
            }
          })
          .then(data => setTimeList(data.data))
          .catch(error => {
            console.log(error);
            setTimeList([]);
          });
      }
    } else {
      setTimeList([]);
    }
  }, [state.date, routeList, selectedSectorID]);

  const handleSelectedSectorIDChanged = e =>
    setSelectedSectorID(e.target.value);

  const handleRouteIDChanged = e =>
    setState({ ...state, routeID: e.target.value });

  const handleHasRouteRestrictionChanged = e =>
    setState({ ...state, hasRouteRestriction: e.target.checked });

  const handleHasTimeRestrictionChanged = e =>
    setState({ ...state, hasTimeRestriction: e.target.checked });

  const onPressDelete = () => {
    setDisable(true);
    bookingApi
      .delete("/PeakHourTrips/" + id, {
        signal: deletePeakHourTripsSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisable(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/PeakHourTripsList/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>DELETE PEAK HOUR TRIP</h4>
          </CardHeader>
          <CardBody>
            <p>Delete this Peak Per Hour Trip?</p>
            <Button onClick={() => props.history.goBack()} value="Back">
              CANCEL
            </Button>
            <Button
              onClick={() => onPressDelete()}
              color="rose"
              type="submit"
              value="Submit"
              disabled={disable}
            >
              DELETE
            </Button>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selSector"
                    className={classes.selectLabel}
                  >
                    Sector
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    disabled
                    value={selectedSectorID ?? ""}
                    onChange={handleSelectedSectorIDChanged}
                  >
                    {sectorList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <b>{record.code}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <InputLabel className={classes.selectLabel}>
                  Departure Date
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.date}
                    disabled
                    onChange={e =>
                      setState({
                        ...state,
                        Date: e === "" ? null : e,
                        Time: null
                      })
                    }
                    dateFormat={tripDateFormat}
                    timeFormat={false}
                    utc={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={state.hasRouteRestriction}
                      onChange={handleHasRouteRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Route Restriction"
                />
              </GridItem>
              {state.hasRouteRestriction && (
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selRoute"
                      className={classes.selectLabel}
                    >
                      Route
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      disabled
                      value={state.routeID ?? ""}
                      onChange={handleRouteIDChanged}
                    >
                      {routeList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{record.code}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasTimeRestriction}
                      onChange={handleHasTimeRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  disabled
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has Time Restriction"
                />
              </GridItem>
              {state.hasTimeRestriction && (
                <GridItem xs={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selTime"
                      className={classes.selectLabel}
                    >
                      Time
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      disabled
                      value={state.time ?? ""}
                      onChange={e =>
                        setState({ ...state, time: e.target.value })
                      }
                    >
                      {timeList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.tripTime}
                            key={record.tripTime}
                          >
                            <b>{`${record.tripTime.substring(
                              0,
                              2
                            )}:${record.tripTime.substring(2, 4)}`}</b>{" "}
                            {record.timezoneLabel}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

deletePeakHourTrips.propTypes = {
  history: PropTypes.object
};
