import React from "react";

export default function GetNotification(notification) {
  let data = JSON.parse(notification.data);
  switch (notification.type) {
    case "creditmonitoring.top_up_created":
      return {
        content: (
          <>
            <b>{data.Agent.Code}</b> has created a new Top Up.
            <br />
            No: #<b>{data.No}</b>
          </>
        ),
        link: "/admin/TopUps/Detail/" + data.ID
      };
    case "creditmonitoring.top_up_approved":
      return {
        content: (
          <>
            <b>{data.Agent.Code}</b> Top Up has been Approved.
            <br />
            No: #<b>{data.No}</b>
          </>
        ),
        link: "/admin/TopUps/Detail/" + data.ID
      };
    case "order.order_cancellation_requested":
      return {
        content: (
          <>
            <b>{data.Agent.Code}</b> has requested to cancel order.
            <br />
            No: #<b>{data.No}</b>
          </>
        ),
        link: "/admin/OrderCancellations/Detail/" + data.ID
      };
    case "booking.agent_booking_cancellation_requested":
      return {
        content: (
          <>
            <b>{data.Agent.Code}</b> has requested to cancel Booking.
            <br />
            No: #<b>{data.ConfirmationCode}</b>
          </>
        ),
        link: "/admin/AgentBookingCancellations/Detail/" + data.ID
      };
    default:
      return null;
  }
}
