import { MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import FeePolicyDetailList from "./FeePolicyDetailList";
import PublicationStatus from "enums/PublicationStatus";
import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

const defaultState = {
  code: "",
  description: ""
};

export default function Detail(props) {
  const [feePolicy, setFeePolicy] = useState({ ...defaultState });

  const { id } = useParams();

  const abortController = React.useRef(new AbortController());

  useEffect(() => {
    bookingApi
      .get("FeePolicies/" + id, {
        signal: abortController.current.signal
      })
      .then(data => setFeePolicy(data))
      .catch(error => console.log(error));

    return () => abortController.current.abort();
  }, []);

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>FEE POLICY</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <p>
              <i>
                *FeePolicy status is &apos;
                {PublicationStatus(feePolicy.publicationStatus)}&apos;.
              </i>
            </p>
            {feePolicy.publicationStatus === 0 && (
              <>
                {(token["booking.fee_policies.publish"] === "allowed" ||
                  token.is_admin === "True") && (
                  <Link to={"/admin/FeePolicy/Publish/" + id}>
                    <Button color="rose" value="Publish">
                      PUBLISH
                    </Button>
                  </Link>
                )}
                {(token["booking.fee_policies.write"] === "allowed" ||
                  token.is_admin === "True") && (
                  <Link to={"/admin/FeePolicy/Delete/" + id}>
                    <Button color="rose" value="Delete">
                      DELETE
                    </Button>
                  </Link>
                )}
                {(token["booking.fee_policies.write"] === "allowed" ||
                  token.is_admin === "True") && (
                  <Link to={"/admin/FeePolicy/Edit/" + id}>
                    <Button color="rose" type="submit" value="Edit">
                      EDIT
                    </Button>
                  </Link>
                )}
              </>
            )}
            <GridContainer>
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: feePolicy.code ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                  disabled
                  labelText="Description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: feePolicy.description ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <FeePolicyDetailList id={id} status={feePolicy.publicationStatus} />
      </GridItem>
    </GridContainer>
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
