import React, { useEffect, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { adminApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const [login, setLogin] = React.useState({
    username: null,
    password: null,
    rememberMe: false
  });

  const handleUsernameChanged = e =>
    setLogin({
      ...login,
      username: e.target.value === "" ? null : e.target.value
    });

  const handlePasswordChanged = e =>
    setLogin({
      ...login,
      password: e.target.value === "" ? null : e.target.value
    });

  const handleRememberMeChanged = e =>
    setLogin({ ...login, rememberMe: e.target.checked });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    adminApi
      .post("/login", login, {
        signal: source.current.signal
      })
      .then(data => {
        localStorage.setItem("Authorization", "Bearer " + data.access_token);
        window.location.href = "/admin/Dashboard";
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log In</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleUsernameChanged,
                    value: login.username === null ? "" : login.username,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handlePasswordChanged,
                    value: login.password === null ? "" : login.password,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={login.rememberMe}
                      onChange={handleRememberMeChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Remember Me"
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={disabled}
                  type="submit"
                  color="rose"
                  simple
                  size="lg"
                  block
                >
                  {disabled ? "LOGGING IN" : "Let's Go"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
