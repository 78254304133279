import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { useParams, Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { travelApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import { toast, ToastContainer } from "react-toastify";
import ProductDialog from "components/ProductDialog/ProductDialog";

const useStyles = makeStyles(styles);

const EditAgentMerchantProductPricing = props => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [input, setInput] = useState({
    productID: null,
    agentID: null,
    startDate: null,
    untilDate: null,
    ageVariant: "",
    price: 0,
    ageRangeFrom: "",
    ageRangeUntil: ""
  });
  const [productData, setProductData] = React.useState({
    id: "",
    name: ""
  });

  const [agentData, setAgentData] = React.useState({
    id: "",
    name: "",
    billingCurrency: null
  });
  const [showProductModal, setShowProductModal] = React.useState(false);

  const editAgentMerchantProductPricingSignal = useRef(new AbortController());

  const { id } = useParams();

  useEffect(() => {
    travelApi
      .get("agentpricings/" + id, {
        signal: editAgentMerchantProductPricingSignal.current.signal
      })
      .then(resp => {
        setProductData({
          id: resp.product.id,
          name: resp.product.name
        });
        setAgentData({
          billingCurrency: resp.agent.billingCurrency,
          id: resp.agent.id,
          name: resp.agent.name
        });
        setInput({
          ...input,
          agentID: resp.agent.id,
          ageRangeFrom: resp.ageRangeFrom,
          ageRangeUntil: resp.ageRangeUntil,
          ageVariant: resp.ageVariant,
          price: resp.price,
          productID: resp.product.id,
          startDate: new Date(resp.startDate),
          untilDate: new Date(resp.untilDate)
        });
      });

    return () => editAgentMerchantProductPricingSignal.current.abort();
  }, []);

  const handleStartDateChanged = e => {
    if (e !== "") {
      const dateTemp = new Date(e);
      dateTemp.setHours(7);
      setInput({
        ...input,
        startDate: dateTemp
      });
    } else {
      setInput({
        ...input,
        startDate: null
      });
    }
  };

  const handleUntilDateChanged = e => {
    if (e !== "") {
      const dateTemp = new Date(e);
      dateTemp.setHours(7);
      setInput({
        ...input,
        untilDate: dateTemp
      });
    } else {
      setInput({
        ...input,
        untilDate: null
      });
    }
  };

  const handleProductIDChanged = e => {
    if (e) {
      setProductData({ id: e.id, name: e.name });
      setShowProductModal(false);
    }
  };

  const handlePriceChanged = e =>
    setInput({
      ...input,
      price:
        e.target.value === "" ? null : e.target.value.replace(/[^0-9]/g, "")
    });

  const handleAgeVariantChanged = e =>
    setInput({
      ...input,
      ageVariant: e.target.value === "" ? null : e.target.value
    });

  const handleAgeRangeFromChanged = e =>
    setInput({
      ...input,
      ageRangeFrom: e.target.value === "" ? null : e.target.value
    });

  const handleAgeRangeUntilChanged = e =>
    setInput({
      ...input,
      ageRangeUntil: e.target.value === "" ? null : e.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    let body = { ...input };
    body.agentID = agentData.id;
    body.productID = productData.id;

    let isValid = true;
    let message =
      "One or more validation errors occurred. Please check your data.";

    if (isValid) {
      setDisabled(true);

      const resolveFromTravelApi = new Promise((resolve, reject) => {
        travelApi
          .put("agentPricings/" + id, body, {
            signal: editAgentMerchantProductPricingSignal.current.signal
          })
          .then(() => {
            resolve({ isSuccess: true });
          })
          .catch(error => {
            reject({ isSuccess: false, error });
          });
      });

      toast
        .promise(resolveFromTravelApi, {
          pending: "...Submit data",
          success: {
            render({ data }) {
              if (data.isSuccess) {
                return `Success submit data`;
              } else {
                return `Error submit data: ${JSON.stringify(
                  data.error?.response.data.title
                )}`;
              }
            }
          },
          error: {
            render({ data }) {
              return `Error submit data: ${JSON.stringify(
                data.error?.response.data.title
              )}`;
            }
          }
        })
        .then(resp => {
          if (resp && resp.isSuccess) {
            setRedirect(true);
          }
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      toast.error(message);
    }
  };
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + input.agentID} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              EDIT MERCHANT PRODUCT PRICING
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Merchant Product
                    </InputLabel>
                    <Input
                      onClick={() => setShowProductModal(prev => !prev)}
                      className={classes.select}
                      value={productData ? productData.name : ""}
                    />
                    <ProductDialog
                      openModal={showProductModal}
                      setOpenModal={val => setShowProductModal(val)}
                      onSelect={handleProductIDChanged}
                      agentID={agentData.id}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText={
                      "Price " +
                      `${
                        agentData.billingCurrency
                          ? "(" + agentData.billingCurrency.code + ")"
                          : ``
                      }`
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handlePriceChanged,
                      value: input.price === null ? "" : input.price
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.startDate}
                      onChange={handleStartDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Start Date"
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.untilDate}
                      onChange={handleUntilDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      inputProps={{
                        placeholder: "Until Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    labelText="Age Variant"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeVariantChanged,
                      value: input.ageVariant
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6}>
                  <CustomInput
                    labelText="Age Range From"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeRangeFromChanged,
                      value: input.ageRangeFrom,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <CustomInput
                    labelText="Age Range Until"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleAgeRangeUntilChanged,
                      value: input.ageRangeUntil,
                      type: "number"
                    }}
                  />
                </GridItem>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <ToastContainer theme="light" />
    </GridContainer>
  );
};

export default EditAgentMerchantProductPricing;

EditAgentMerchantProductPricing.propTypes = {
  history: PropTypes.object,
  id: PropTypes.string
};
