import { MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import FeePolicyDetailList from "./FeePolicyDetailList";

const useStyles = makeStyles(styles);

const defaultState = {
  code: "",
  description: ""
};

export default function Publish(props) {
  const [redirect, setRedirect] = useState(false);
  const [feePolicy, setFeePolicy] = useState({ ...defaultState });
  const [disabled, setDisabled] = React.useState(false);

  const { id } = useParams();

  const abortController = React.useRef(new AbortController());

  useEffect(() => {
    bookingApi
      .get("FeePolicies/" + id, {
        signal: abortController.current.signal
      })
      .then(data => setFeePolicy(data))
      .catch(error => console.log(error));

    return () => abortController.current.abort();
  }, []);

  const handleSubmit = () => {
    setDisabled(true);
    bookingApi
      .post("/FeePolicies/Publish", {
        id: id
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/FeePolicy/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>PUBLISH FEE POLICY</h4>
          </CardHeader>
          <CardBody>
            <i>
              *Please review the Fee Policy Information before publish it. Once
              it is published, it cannot be reverted.
            </i>
            <form onSubmit={handleSubmit}>
              <Button onClick={() => props.history.goBack()} value="Cancel">
                Cancel
              </Button>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                PUBLISH
              </Button>
            </form>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: feePolicy.code ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                  disabled
                  labelText="Description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: feePolicy.description ?? "",
                    disabled: true
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <FeePolicyDetailList id={id} status={feePolicy.status} />
      </GridItem>
    </GridContainer>
  );
}

Publish.propTypes = {
  history: PropTypes.object
};
