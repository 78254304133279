import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { formatDateTime } from "functions/Helper";

import { GetVoidStatusDescription } from "enums/VoidStatus";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function ViewVoid(props) {
  const { objVoid } = props;
  const classes = useStyles();
  return (
    <>
      <CustomInput
        labelText="No"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: objVoid.no
        }}
      />
      <CustomInput
        labelText="Note"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: objVoid.note === null ? "" : objVoid.note
        }}
      />
      <CustomInput
        labelText="Status"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: GetVoidStatusDescription(objVoid.status)
        }}
      />
      {objVoid.status === 0 && (
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Created At"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: formatDateTime(new Date(objVoid.createdAt + "Z"))
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Created By"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: objVoid.createdBy === null ? "" : objVoid.createdBy
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {objVoid.status === 1 && (
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Submitted At"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: formatDateTime(new Date(objVoid.submittedAt + "Z"))
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Submitted By"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: objVoid.submittedBy === null ? "" : objVoid.submittedBy
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {objVoid.status === 2 && (
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Approved At"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: formatDateTime(new Date(objVoid.approvedAt + "Z"))
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Approved By"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: objVoid.approvedBy === null ? "" : objVoid.approvedBy
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {objVoid.status === 3 && (
        <>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Rejected At"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: formatDateTime(new Date(objVoid.rejectedAt + "Z"))
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Rejected By"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: objVoid.rejectedBy === null ? "" : objVoid.rejectedBy
                }}
              />
            </GridItem>
          </GridContainer>
          <CustomInput
            labelText="Reject Reason"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: objVoid.rejectReason === null ? "" : objVoid.rejectReason
            }}
          />
        </>
      )}
      <FormControlLabel
        disabled
        control={
          <Checkbox
            checked={objVoid.refund}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        classes={{
          label: classes.label,
          root: classes.labelRoot
        }}
        label="Refund"
      />
      {objVoid.refund === true && (
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText="Agent"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: objVoid.agent.name
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText="Billing Currency"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value:
                  objVoid.agent.billingCurrency === null
                    ? ""
                    : objVoid.agent.billingCurrency.code
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <CustomInput
              labelText="Amount"
              id="numAmount"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: objVoid.amount.toLocaleString()
              }}
            />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}

ViewVoid.propTypes = {
  objVoid: PropTypes.object
};
