import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import View from "./View";
import ViewDetailList from "./ViewDetailList";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { bookingApi } from "functions/apis";
import { GetFeeCalculationTypes } from "enums/FeeCalculationType";

const useStyles = makeStyles(styles);

export default function Approve(props) {
  const source = useRef(new AbortController());
  const { id } = useParams();
  const [
    agentBookingCancellation,
    setAgentBookingCancellation
  ] = React.useState(null);

  const [state, setState] = React.useState({
    id: id,
    feeCalculation: {
      calculationType: 0,
      value: 0
    }
  });

  useEffect(() => {
    bookingApi
      .get("/AgentBookingCancellations/" + id, {
        signal: source.current.signal
      })
      .then(data => setAgentBookingCancellation(data))
      .catch(error => console.log(error));

    return () => source.current.abort();
  }, [id]);

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleCalculationTypeChanged = e =>
    setState({
      ...state,
      feeCalculation: {
        ...state.feeCalculation,
        calculationType: e.target.value
      }
    });

  const handleValueChanged = e =>
    setState({
      ...state,
      feeCalculation: {
        ...state.feeCalculation,
        value: e.target.value
      }
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/AgentBookingCancellations/Approve", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return (
    agentBookingCancellation !== null &&
    (redirect ? (
      <Redirect to="/admin/AgentBookingCancellations/Submitted" />
    ) : (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>APPROVE CANCELLATION</h4>
            </CardHeader>
            <CardBody>
              <p>
                <i>
                  *Please review before Approve the Request. Once it is
                  approved, all the voucher utilised will be reinstated. Wallet
                  deduction will be refunded, and the Check In of this/these
                  passenger(s) will be cancelled.
                </i>
              </p>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCalculationType"
                    className={classes.selectLabel}
                  >
                    CALCULATION TYPE
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.feeCalculation.calculationType ?? ""}
                    onChange={handleCalculationTypeChanged}
                  >
                    {GetFeeCalculationTypes().map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.key}
                          key={record.key}
                        >
                          <p>{record.description}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CustomInput
                  disabled
                  labelText="Value"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleValueChanged,
                    value: state.feeCalculation.value ?? "",
                    type: "number"
                  }}
                />
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  CANCEL
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  APPROVE
                </Button>
              </form>
            </CardBody>
          </Card>
          <View
            agentBookingCancellation={agentBookingCancellation}
            history={props.history}
          />
          <ViewDetailList agentBookingCancellation={agentBookingCancellation} />
        </GridItem>
      </GridContainer>
    ))
  );
}

Approve.propTypes = {
  history: PropTypes.object
};
