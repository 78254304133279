import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// @material-ui/core components

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import View from "./View";
import ViewDetailList from "./ViewDetailList";

import { bookingApi } from "functions/apis";

export default function Detail(props) {
  const source = useRef(new AbortController());
  const { id } = useParams();
  const [
    agentBookingCancellation,
    setAgentBookingCancellation
  ] = React.useState(null);

  useEffect(() => {
    bookingApi
      .get("/AgentBookingCancellations/" + id, {
        signal: source.current.signal
      })
      .then(data => setAgentBookingCancellation(data))
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, [id]);

  return (
    agentBookingCancellation !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <View
            agentBookingCancellation={agentBookingCancellation}
            history={props.history}
          />
          <ViewDetailList agentBookingCancellation={agentBookingCancellation} />
        </GridItem>
      </GridContainer>
    )
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
