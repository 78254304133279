export function GetAgentBookingCancellationProcessStatuses() {
  return [
    {
      key: 0,
      description: "None"
    },
    {
      key: 1,
      description: "Voucher Reinstated"
    },
    {
      key: 2,
      description: "Refunded"
    },
    {
      key: 3,
      description: "Fee Charged"
    },
    {
      key: 99,
      description: "Completed"
    }
  ];
}

export function GetAgentBookingCancellationProcessStatusDescription(value) {
  let agentBookingCancellationProcessStatus = GetAgentBookingCancellationProcessStatuses().find(
    item => item.key === value
  );
  return agentBookingCancellationProcessStatus.description;
}
