import React from "react";
import PropTypes from "prop-types";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

export default function ViewAgent(props) {
  const { agent } = props;
  return (
    <>
      <CustomInput
        labelText="Code"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: agent.code
        }}
      />
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: agent.name
        }}
      />
    </>
  );
}

ViewAgent.propTypes = {
  agent: PropTypes.object
};
