import { Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import moment from "moment";
import { tripDateFormat } from "functions/Helper";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ViewOnlineBookingsPromoCode(props) {
  const [data, setData] = useState(null);

  const { id } = useParams();

  const viewOnlineBookingsPromoCodeSignal = React.useRef(new AbortController());

  const getData = () => {
    bookingApi
      .get("OnlineBookingPromoCodes/" + id, {
        signal: viewOnlineBookingsPromoCodeSignal.current.signal,
        params: {
          ID: id
        }
      })
      .then(data => {
        const time = new Date();
        if (data.time && data.time.length === 4) {
          const hours = `${data.time[0]}${data.time[1]}`;
          const minute = `${data.time[2]}${data.time[3]}`;
          time.setHours(hours);
          time.setMinutes(minute);
        }
        setData({
          ...data,
          time: time,
          effectiveDate: moment(new Date(data.effectiveDate + "Z")),
          expiryDate: moment(new Date(data.expiryDate + "Z")),
          travelDateStart:
            data.travelDateStart !== null
              ? new Date(data.travelDateStart + "Z")
              : null,
          travelDateUntil:
            data.travelDateUntil !== null
              ? new Date(data.travelDateUntil + "Z")
              : null,
          allowedDayGroup: data.allowedDayGroup,
          allowedSectors: data.allowedSectors.map(r => r.sector)
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData();
    return () => {
      viewOnlineBookingsPromoCodeSignal.current.abort();
    };
  }, []);

  const classes = useStyles();

  return (
    data !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                VIEW ONLINE BOOKING PROMO CODES
              </h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Promo Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled
                    inputProps={{
                      value: data.promoCode,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Discount Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled
                    inputProps={{
                      type: "number",
                      value: data.discountAmount ?? "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Minimal Trip"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: data.minimalTrip ?? "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Limit"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: data.limit ?? "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Effective Date
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={data.effectiveDate}
                      dateFormat={tripDateFormat}
                      timeFormat={true}
                      disabled
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date",
                        disabled: true
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Expiry Date
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={data.expiryDate}
                      disabled
                      dateFormat={tripDateFormat}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date",
                        disabled: true
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="Currency"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      value: data.currency.code,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={data.hasUserRestriction}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        disabled
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has User Restriction"
                  />
                </GridItem>
                {data.hasUserRestriction && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="User Email"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: data.userEmail,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked={data.hasDayGroupRestriction}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has DayGroup Restriction"
                  />
                </GridItem>
                {data.hasDayGroupRestriction && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Allowed DayGroup"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: data.allowedDayGroup.name,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked={data.hasSectorRestriction}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Sector Restriction"
                  />
                </GridItem>
                {data.hasSectorRestriction && (
                  <GridItem xs={12} md={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <p>Allowed Sectors:</p>
                      <div className={classes.chips}>
                        {data.allowedSectors.map(sector => {
                          return (
                            <div key={sector.id} className={classes.chips}>
                              <Chip
                                key={sector.id}
                                label={<b>{sector.name}</b>}
                                className={classes.chip}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked={data.hasTravelDateRestriction}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Travel Date Restriction"
                  />
                </GridItem>
                {data.hasTravelDateRestriction && (
                  <GridItem xs={12} md={6}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Date Start
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        disabled
                        value={data.travelDateStart}
                        dateFormat={tripDateFormat}
                        timeFormat={false}
                        utc={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "TravelDate Start"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                {data.hasTravelDateRestriction && (
                  <GridItem xs={12} md={6}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Date Until
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        disabled
                        value={data.travelDateUntil}
                        dateFormat={tripDateFormat}
                        timeFormat={false}
                        utc={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "TravelDate Until"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={data.hasTimeRestriction}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Time Restriction"
                  />
                </GridItem>
                {data.hasTimeRestriction && (
                  <GridItem xs={12} md={12}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Time
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        disabled
                        value={data.time}
                        dateFormat={false}
                        timeFormat={true}
                        inputProps={{
                          placeholder: "Time"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked={data.isRestrictedToCardPayment}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Restricted To Card Payment"
                  />
                </GridItem>
                {data.isRestrictedToCardPayment && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      disabled
                      labelText="Card Number Prefix"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        value: data.cardNumberPrefix
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={data.hasMinimalAppVersion}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Minimal App Version"
                  />
                </GridItem>
                {data.hasMinimalAppVersion && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Minimal Android App Version"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: data.minimalAndroidAppVersion,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
                {data.hasMinimalAppVersion && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Minimal IOS App Version"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: data.minimalIOSAppVersion,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

ViewOnlineBookingsPromoCode.propTypes = {
  history: PropTypes.object
};
