import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { bookingApi } from "functions/apis";

import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function EditBookingConfiguration(props) {
  const abortController = new AbortController();

  const [agent, setAgent] = React.useState(null);
  const [billingCurrency, setBillingCurrency] = React.useState(null);
  const [data, setData] = React.useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (
      token["booking.fee_policy_groups.read"] === "allowed" ||
      token.is_admin === "True"
    )
      bookingApi
        .get("/FeePolicyGroups", {
          signal: abortController.signal,
          params: {
            filter: {
              searchString: "",
              publicationStatus: 1,
              sort: 3
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setData(data.records));

    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (token["booking.agents.read"] === "allowed" || token.is_admin === "True")
      bookingApi
        .get("/Agents/" + id, {
          signal: abortController.signal
        })
        .then(data => {
          setAgent({
            overrideBookingTransferFee: data.overrideBookingTransferFee,
            bookingTransferFee: data.bookingTransferFee,
            cancellationPolicyGroupID: data.cancellationFeePolicyGroup?.id,
            transferPolicyGroupID: data.transferFeePolicyGroup?.id
          });
          setBillingCurrency(data.billingCurrency);
        })
        .catch(error => console.log(error));
  }, [id]);

  const handleOverrideBookingTransferFeeChanged = e =>
    setAgent({
      ...agent,
      overrideBookingTransferFee: e.target.checked,
      bookingTransferFee: 0
    });

  const handleCancellationPolicyGroupIDChanged = e => {
    setAgent({
      ...agent,
      cancellationPolicyGroupID: e.target.value
    });
  };

  const handleTransferPolicyGroupIDChanged = e => {
    setAgent({
      ...agent,
      transferPolicyGroupID: e.target.value
    });
  };

  const handleBookingTransferFeeChanged = e => {
    let newBookingTransferFee = parseFloat(e.target.value);
    if (newBookingTransferFee !== agent.bookingTransferFee)
      setAgent({
        ...agent,
        bookingTransferFee: newBookingTransferFee
      });
  };

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .put("/Agents/" + id, agent, {
        signal: abortController.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + id} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              EDIT BOOKING CONFIGURATION
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            {agent !== null && (
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agent.overrideBookingTransferFee}
                          onChange={handleOverrideBookingTransferFeeChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Override Booking Transfer Fee"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Billing Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: billingCurrency?.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Booking Transfer Fee"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !agent.overrideBookingTransferFee,
                        onChange: handleBookingTransferFeeChanged,
                        value:
                          agent.bookingTransferFee === null
                            ? ""
                            : agent.bookingTransferFee
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selCancellationPolicyGroup"
                        className={classes.selectLabel}
                      >
                        CANCELLATION POLICY GROUP
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={agent.cancellationPolicyGroupID ?? ""}
                        onChange={handleCancellationPolicyGroupIDChanged}
                      >
                        {data.map(record => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={record.id}
                              key={record.id}
                            >
                              <p>
                                <b>{record.code}</b>
                              </p>
                              <p>{record.name}</p>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selTransferPolicyGroup"
                        className={classes.selectLabel}
                      >
                        TRANSFER POLICY GROUP
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={agent.transferPolicyGroupID ?? ""}
                        onChange={handleTransferPolicyGroupIDChanged}
                      >
                        {data.map(record => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={record.id}
                              key={record.id}
                            >
                              <p>
                                <b>{record.code}</b>
                              </p>
                              <p>{record.name}</p>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditBookingConfiguration.propTypes = {
  history: PropTypes.object
};
