import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

import ApprovedList from "views/AgentBookingTransfer/ApprovedList.js";
import SubmittedList from "views/AgentBookingTransfer/SubmittedList.js";
import RejectedList from "views/AgentBookingTransfer/RejectedList.js";
import Detail from "views/AgentBookingTransfer/Detail.js";
import Approve from "views/AgentBookingTransfer/Approve.js";
import Reject from "views/AgentBookingTransfer/Reject.js";
import { token } from "functions/Helper";

const agentBookingTransfer = () => {
  let views = [];

  if (
    token["booking.agent_booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingTransfers/Submitted",
        name: "Submitted",
        mini: "SU",
        component: SubmittedList,
        layout: "/admin"
      },
      {
        path: "/AgentBookingTransfers/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedList,
        layout: "/admin"
      },
      {
        path: "/AgentBookingTransfers/Rejected",
        name: "Rejected",
        mini: "RJ",
        component: RejectedList,
        layout: "/admin"
      }
    ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agent Booking Transfers",
        icon: AssignmentLateIcon,
        state: "agentBookingTransferCollapse",
        views: views
      }
    ];
  return [];
};
const agentBookingTransferHidden = () => {
  let views = [];
  if (
    token["booking.agent_booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/AgentBookingTransfer/Detail",
      name: "Agent Booking Transfer Detail",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.agent_booking_cancellations.approve"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingTransfer/Approve",
        name: "Approve",
        component: Approve,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.agent_booking_cancellations.reject"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingTransfer/Reject",
        name: "Reject",
        component: Reject,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agent Booking Transfer Hidden",
        state: "agentBookingTransferHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};
export { agentBookingTransfer, agentBookingTransferHidden };
