import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { GetVoidStatusDescription } from "enums/VoidStatus";

import { voucherApi } from "functions/apis";
import { token } from "functions/Helper";
import ViewVoid from "./ViewVoid";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import VoidDetailList from "./VoidDetailList";

const useStyles = makeStyles(styles);

export default function VoidDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id } = useParams();
  const [objVoid, setObjVoid] = React.useState(null);

  useEffect(() => {
    voucherApi
      .get("/Voids/" + id, {
        signal: source.current.signal
      })
      .then(data => setObjVoid(data))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    objVoid !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>VOID</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <p>
                <i>
                  *Void status is &apos;
                  <b>{GetVoidStatusDescription(objVoid.status)}</b>&apos;.
                </i>
              </p>
              {objVoid.status === 0 &&
                (token["voucher.voids.approve"] === "allowed" ||
                  token.is_admin === "True") && (
                  <Link to={"/admin/Void/Submit/" + objVoid.id}>
                    <Button color="rose" value="Submit">
                      SUBMIT
                    </Button>
                  </Link>
                )}
              {objVoid.status === 1 && (
                <>
                  {(token["voucher.voids.approve"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/Void/Approve/" + objVoid.id}>
                      <Button color="rose" value="Approve">
                        APPROVE
                      </Button>
                    </Link>
                  )}
                  {(token["voucher.voids.reject"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/Void/Reject/" + objVoid.id}>
                      <Button color="rose" value="Reject">
                        REJECT
                      </Button>
                    </Link>
                  )}
                </>
              )}
              <ViewVoid objVoid={objVoid} />
            </CardBody>
          </Card>
          <VoidDetailList id={id} status={objVoid.status} />
        </GridItem>
      </GridContainer>
    )
  );
}

VoidDetail.propTypes = {
  history: PropTypes.object
};
