import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi, masterApi, adminApi } from "functions/apis";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function CreateAgent(props) {
  const source = useRef(new AbortController());

  const [businessCategoryList, setBusinessCategoryList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [userNameList, setUserNameList] = React.useState([]);

  useEffect(() => {
    if (
      token["master.business_categories.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/BusinessCategories", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setBusinessCategoryList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.countries.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Countries", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setCountryList(data.records))
        .catch(error => console.log(error));

    if (
      token["admin.users.read_username"] === "allowed" ||
      token.is_admin === "True"
    )
      adminApi
        .get("/Users/UserNames", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null
            }
          }
        })
        .then(data => setUserNameList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);
  const [state, setState] = React.useState({});

  const handleCodeChanged = e =>
    setState({ ...state, code: e.target.value === "" ? null : e.target.value });
  const handleNameChanged = e =>
    setState({ ...state, name: e.target.value === "" ? null : e.target.value });
  const handleBusinessCategoryIDChanged = e =>
    setState({
      ...state,
      businessCategoryID: e.target.value === "" ? null : e.target.value
    });
  const handleLocationIDChanged = e =>
    setState({
      ...state,
      locationID: e.target.value === "" ? null : e.target.value
    });
  const handleEmailChanged = e =>
    setState({
      ...state,
      email: e.target.value === "" ? null : e.target.value
    });
  const handleContactPersonChanged = e =>
    setState({
      ...state,
      contactPerson: e.target.value === "" ? null : e.target.value
    });
  const handleCompanyAddressChanged = e =>
    setState({
      ...state,
      companyAddress: e.target.value === "" ? null : e.target.value
    });
  const handleOfficeContactNumberChanged = e =>
    setState({
      ...state,
      officeContactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleMobileContactNumberChanged = e =>
    setState({
      ...state,
      mobileContactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleReferrerChanged = e =>
    setState({
      ...state,
      referrer: e.target.value === "" ? null : e.target.value
    });
  const handleAccountManagerChanged = e =>
    setState({
      ...state,
      accountManager: e.target.value === "" ? null : e.target.value
    });
  const handleFirstReferrerChanged = e =>
    setState({
      ...state,
      firstReferrer: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .post("/Agents", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/Agents/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE AGENT</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleCodeChanged,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: state.name === null ? "" : state.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleEmailChanged,
                  value: state.email === null ? "" : state.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selBusinessCategory"
                      className={classes.selectLabel}
                    >
                      Business Category
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        state.businessCategoryID === null
                          ? ""
                          : state.businessCategoryID
                      }
                      onChange={handleBusinessCategoryIDChanged}
                    >
                      {businessCategoryList.map(businessCategory => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={businessCategory.id}
                            key={businessCategory.id}
                          >
                            <b>{businessCategory.code}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selLocation"
                      className={classes.selectLabel}
                    >
                      Location
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={state.locationID === null ? "" : state.locationID}
                      onChange={handleLocationIDChanged}
                    >
                      {countryList.map(country => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={country.id}
                            key={country.id}
                          >
                            <b>{country.name}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Person"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleContactPersonChanged,
                      value:
                        state.contactPerson === null ? "" : state.contactPerson
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleCompanyAddressChanged,
                      value:
                        state.companyAddress === null
                          ? ""
                          : state.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Office)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleOfficeContactNumberChanged,
                      value:
                        state.officeContactNumber === null
                          ? ""
                          : state.officeContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Mobile)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleMobileContactNumberChanged,
                      value:
                        state.mobileContactNumber === null
                          ? ""
                          : state.mobileContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Referrer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleReferrerChanged,
                      value: state.referrer === null ? "" : state.referrer
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAccountManager"
                      className={classes.selectLabel}
                    >
                      Account Manager
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        state.accountManager === null
                          ? ""
                          : state.accountManager
                      }
                      onChange={handleAccountManagerChanged}
                    >
                      {userNameList.map(userName => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={userName}
                            key={userName}
                          >
                            <b>{userName}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="First Referrer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleFirstReferrerChanged,
                      value:
                        state.firstReferrer === null ? "" : state.firstReferrer
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateAgent.propTypes = {
  history: PropTypes.object
};
