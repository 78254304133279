import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CommissionList from "views/Commission/CommissionList.js";
import CreateCommission from "views/Commission/CreateCommission.js";

import { token } from "functions/Helper";

const commission = () => {
  let views = [];
  if (
    token["creditmonitoring.commissions.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/CommissionList",
      name: "Commissions",
      icon: LocalAtmIcon,
      component: CommissionList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const commissionHidden = () => {
  let views = [];
  if (
    token["creditmonitoring.commissions.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Commission/Create",
      name: "Create Commission",
      component: CreateCommission,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Commission Hidden",
        state: "commissionHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { commission, commissionHidden };
