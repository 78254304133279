import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { token } from "functions/Helper";
import { bookingApi } from "functions/apis";
import { useState } from "react";
import { formatDateTime } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function DeactivatedPeakHourTripsList() {
  // const [loading, setLoading] = useEffect(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [pages, setPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const peakHourTripsSignal = new AbortController();
  const deleteSignal = new AbortController();

  const getPeakData = () => {
    setLoading(true);

    bookingApi
      .get("PeakHourTrips", {
        signal: peakHourTripsSignal.signal,
        params: {
          filter: {
            PublicationStatus: 2
          },
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  {token.is_admin === "True" && (
                    <Link to={"/admin/PeakHourTrips/Edit/" + record.id}>
                      <Button color="danger" type="button" value="CANCEL">
                        <EditIcon /> EDIT
                      </Button>
                    </Link>
                  )}
                  <Link to={"/admin/PeakHourTrips/Delete/" + record.id}>
                    <Button color="danger" type="button" value="CANCEL">
                      <DeleteIcon /> DELETE
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getPeakData();
    return () => {
      peakHourTripsSignal.abort();
      deleteSignal.abort();
    };
  }, []);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Peak Hour Trips - Deactivated
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              manual
              pages={pages}
              pageSize={pagination.pageSize}
              page={pagination.pageIndex - 1}
              onPageChange={page => {
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                });
              }}
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  id: "date",
                  Header: "Date",
                  accessor: d => formatDateTime(new Date(d.date))
                },
                {
                  id: "hasRouteRestriction",
                  Header: "Has Route Restriction",
                  accessor: d => d.hasRouteRestriction.toString()
                },
                {
                  Header: "Route ID",
                  accessor: "routeID"
                },
                {
                  id: "hasTimeRestriction",
                  Header: "Has Time Restriction",
                  accessor: d => d.hasTimeRestriction.toString()
                },
                {
                  Header: "Time",
                  accessor: "time"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
