import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { adminApi } from "functions/apis";
import GetNotification from "functions/NotificationHelper";
import moment from "moment";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

export default function Notification(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const markAsRead = (id, callback) =>
    adminApi
      .post(
        "Notifications/Read",
        {
          id: id
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => callback())
      .catch(error => console.log(error));

  const markAsUnread = id =>
    adminApi
      .post(
        "Notifications/Unread",
        {
          id: id
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setReload({}))
      .catch(error => console.log(error));
  const [reload, setReload] = React.useState({});
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    adminApi
      .get("/Notifications", {
        signal: source.current.signal,
        params: {
          filter: {
            isRead: null
          },
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            let notification = GetNotification(record);
            return {
              notification: (
                <Button
                  color={record.isRead ? "info" : "success"}
                  onClick={() =>
                    markAsRead(record.id, () => setRedirect(notification.link))
                  }
                >
                  <div>{notification.content}</div>
                </Button>
              ),
              time: moment(record.time + "Z").fromNow(),
              actions: (
                <div className="actions-right">
                  {record.isRead ? (
                    <Button
                      color="info"
                      className="like"
                      onClick={() => markAsUnread(record.id)}
                    >
                      <ErrorIcon /> Unread
                    </Button>
                  ) : (
                    <Button
                      color="success"
                      className="like"
                      onClick={() => markAsRead(record.id, () => setReload({}))}
                    >
                      <CheckIcon /> Read
                    </Button>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, reload]);

  const [redirect, setRedirect] = React.useState(null);
  const classes = useStyles();
  return redirect !== null ? (
    <Redirect to={redirect} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>NOTIFICATIONS</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              Back
            </Button>
            <Button
              color="rose"
              type="button"
              value="MarkAllAsRead"
              onClick={() =>
                adminApi
                  .post(
                    "/Notifications/ReadAll",
                    {},
                    {
                      signal: source.current.signal
                    }
                  )
                  .then(() => setReload({}))
                  .catch(error => console.log(error))
              }
            >
              MARK ALL AS READ
            </Button>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Notification",
                  accessor: "notification"
                },
                {
                  Header: "Time",
                  accessor: "time"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Notification.propTypes = {
  history: PropTypes.object
};
