import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import DoneAllIcon from "@material-ui/icons/DoneAll";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { bookingApi } from "functions/apis";
import { tripDateFormat, token } from "functions/Helper";
import ListFilter from "./ListFilter";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function CancelErrorList() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    status: 7,
    sort: 1,
    sectorID: null,
    routeID: null,
    date: null,
    time: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    bookingApi
      .get("/CoreApiCheckIns", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/CoreApiCheckIn/Detail/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  {(token["booking.core_api_check_ins.retry_cancel"] ===
                    "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/CoreApiCheckIn/RetryCancel/" + record.id}>
                      <Button color="rose" type="button" value="Retry">
                        <ReplayIcon /> RETRY
                      </Button>
                    </Link>
                  )}
                  {(token[
                    "booking.core_api_check_ins.set_as_cancel_resolved"
                  ] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link
                      to={
                        "/admin/CoreApiCheckIn/SetAsCancelResolved/" + record.id
                      }
                    >
                      <Button
                        color="danger"
                        type="button"
                        value="Set As Resolved"
                      >
                        <DoneAllIcon /> SET AS RESOLVED
                      </Button>
                    </Link>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CANCEL ERROR LIST</h4>
          </CardHeader>
          <CardBody>
            {(token["booking.core_api_check_ins.retry_cancel"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/CoreApiCheckIn/BulkRetryCancelError"}>
                <Button color="rose" type="button" value="Retry">
                  <ReplayIcon /> BULK RETRY
                </Button>
              </Link>
            )}
            <ListFilter filter={filter} setFilter={setFilter} />
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  id: "tripDate",
                  Header: "Trip Date",
                  accessor: d =>
                    Moment(new Date(d.coreApiTrip.date)).format(tripDateFormat)
                },
                {
                  id: "tripTime",
                  Header: "Trip Time",
                  accessor: d => (d.isOpen ? "OPEN TRIP" : d.coreApiTrip.time)
                },
                {
                  id: "route",
                  Header: "Route",
                  accessor: d => d.coreApiTrip.route.code
                },
                {
                  id: "no",
                  Header: "Passport No",
                  accessor: d => d.identification.no
                },
                {
                  id: "fullName",
                  Header: "Full Name",
                  accessor: d => d.identification.fullName
                },
                {
                  id: "cancelReferenceNo",
                  Header: "Cancel Ref. No",
                  accessor: d => d.cancelReferenceNo
                },
                {
                  Header: "Confirmation Code",
                  accessor: "confirmationCode"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
