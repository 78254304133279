export function GetOnlineBookingPromoCodeCalculationTypes() {
  return [
    {
      key: 0,
      description: "Fixed"
    },
    {
      key: 1,
      description: "By Percentage"
    }
  ];
}

export function GetOnlineBookingPromoCodeCalculationTypeDescription(value) {
  let onlineBookingPromoCodeCalculationType = GetOnlineBookingPromoCodeCalculationTypes().find(
    item => item.key === value
  );
  return onlineBookingPromoCodeCalculationType.description;
}
