import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";

import { creditMonitoringApi } from "functions/apis";
import AgentDialog from "components/AgentDialog/AgentDialog";
import { useState } from "react";
import { Input } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CreateCommission(props) {
  const source = useRef(new AbortController());

  const [commissionTypeList, setCommissionTypeList] = React.useState([]);
  const [showModal, setShowModal] = useState(false);
  const [agentName, setAgentName] = useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/CommissionTypes", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCommissionTypeList(data.records))
      .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);

  const [selectedAgentCreditData, setSelectedAgentCreditData] = React.useState(
    null
  );

  const handleAgentIDChanged = e => {
    setState({ ...state, agentID: e.id });
    setShowModal(false);
    setAgentName(e.name);
    creditMonitoringApi
      .get("Agents/" + e.id, {
        signal: source.current.signal
      })
      .then(data => setSelectedAgentCreditData(data))
      .catch(error => console.log(error));
  };

  const hugeAmountWarning = () => {
    if (selectedAgentCreditData === null) return null;

    if (
      (selectedAgentCreditData.billingCurrency.code === "SGD" &&
        state.amount >= 10000) ||
      (selectedAgentCreditData.billingCurrency.code === "IDR" &&
        state.amount >= 100000000)
    ) {
      return (
        <small className={classes.warning}>
          (Large amount payout detected. Please make sure it is correct!)
        </small>
      );
    }
    return null;
  };

  const handleCommissionTypeIDChanged = e => {
    setState({ ...state, commissionTypeID: e.target.value });
  };

  const handleNoteChanged = e =>
    setState({ ...state, note: e.target.value === "" ? null : e.target.value });

  const handleAmountChanged = e =>
    setState({ ...state, amount: e.target.value });

  const handleHasGSTChanged = e =>
    setState({ ...state, hasGST: e.target.checked });

  const [state, setState] = React.useState({
    agentID: null,
    commissionTypeID: null,
    amount: 0,
    hasGST: false,
    note: null
  });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    creditMonitoringApi
      .post(
        "/Commissions",
        {
          ...state,
          amount: parseFloat(state.amount)
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/CommissionList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE COMMISSION</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="selAgent" className={classes.selectLabel}>
                  Agent
                </InputLabel>
                <Input
                  classes={{
                    input: classes.input,
                    disabled: classes.active,
                    root: classes.active,
                    underline: classes.underline
                  }}
                  value={agentName ?? ""}
                  onClick={() => setShowModal(true)}
                  fullWidth
                />
                <AgentDialog
                  openModal={showModal}
                  setOpenModal={val => setShowModal(val)}
                  onSelect={handleAgentIDChanged}
                />
              </FormControl>
              <Button
                onClick={() => setShowModal(prev => !prev)}
                color="rose"
                type="button"
                value="Create"
              >
                Select Agents
              </Button>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCommissionType"
                  className={classes.selectLabel}
                >
                  Commission Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    state.commissionTypeID === null
                      ? ""
                      : state.commissionTypeID
                  }
                  onChange={handleCommissionTypeIDChanged}
                >
                  {commissionTypeList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.description}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        selectedAgentCreditData === null ||
                        selectedAgentCreditData.billingCurrency === null
                          ? ""
                          : selectedAgentCreditData.billingCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleAmountChanged,
                      value: state.amount
                    }}
                  />
                  <b>{hugeAmountWarning()}</b>
                </GridItem>
              </GridContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.hasGST}
                    onChange={handleHasGSTChanged}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Has GST"
              />
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNoteChanged,
                  value: state.note === null ? "" : state.note,
                  multiline: true
                }}
              />
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateCommission.propTypes = {
  history: PropTypes.object
};
