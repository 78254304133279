import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { bookingApi } from "functions/apis";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ViewAppsNotification(props) {
  const [input, setInput] = useState({
    title: "",
    description: "",
    url: "",
    pushNotificationSection: "",
    name: ""
  });
  const { id } = useParams();

  const ViewAppsNotificationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => {
      ViewAppsNotificationSignal.current.abort();
    };
  }, []);

  useEffect(() => {
    if (id) {
      bookingApi
        .get("appsnotifications/" + id, {
          signal: ViewAppsNotificationSignal.current.signal
        })
        .then(resp => {
          setInput({
            ...input,
            name: resp.name ?? "",
            title: resp.title ?? "",
            description: resp.description ?? "",
            pushNotificationSection: resp.notificationSection
              ? resp.notificationSection.name
              : "",
            url: resp.url ?? ""
          });
        });
    }
  }, [id]);

  const handleSubmit = e => {
    e.preventDefault();
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>View Apps Notification</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Name"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.name,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Title"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.title,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Description"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.description,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Url"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.url,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Section"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.pushNotificationSection,
                  disabled: true
                }}
              />
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ViewAppsNotification.propTypes = {
  history: PropTypes.object
};
