import BlockIcon from "@material-ui/icons/Block";

import PendingVoidList from "views/Void/PendingVoidList.js";
import SubmittedVoidList from "views/Void/SubmittedVoidList.js";
import ApprovedVoidList from "views/Void/ApprovedVoidList.js";
import RejectedVoidList from "views/Void/RejectedVoidList.js";
import CreateVoid from "views/Void/CreateVoid.js";
import VoidDetail from "views/Void/VoidDetail.js";

import EditVoucherType from "views/VoucherType/EditVoucherType.js";
import DeleteVoid from "views/Void/DeleteVoid.js";
import SubmitVoid from "views/Void/SubmitVoid.js";
import ApproveVoid from "views/Void/ApproveVoid.js";
import RejectVoid from "views/Void/RejectVoid.js";

import { token } from "functions/Helper";

const voidTransaction = () => {
  let views = [];
  if (token["voucher.voids.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Voids/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingVoidList,
        layout: "/admin"
      },
      {
        path: "/Voids/Submitted",
        name: "Submitted",
        mini: "SU",
        component: SubmittedVoidList,
        layout: "/admin"
      },
      {
        path: "/Voids/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedVoidList,
        layout: "/admin"
      },
      {
        path: "/Voids/Rejected",
        name: "Rejected",
        mini: "RE",
        component: RejectedVoidList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Voids",
        icon: BlockIcon,
        state: "voidCollapse",
        views: views
      }
    ];
  return [];
};

const voidTransactionHidden = () => {
  let views = [];
  if (token["voucher.voids.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Void/Detail",
      name: "Void",
      component: VoidDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["voucher.voids.write"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Void/Create",
        name: "Create Void",
        component: CreateVoid,
        layout: "/admin"
      },
      {
        path: "/Void/Edit",
        name: "Edit Void",
        component: EditVoucherType,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Void/Delete",
        name: "Delete Void",
        component: DeleteVoid,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (token["voucher.voids.submit"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Void/Submit",
      name: "Submit Void",
      component: SubmitVoid,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["voucher.voids.approve"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Void/Approve",
      name: "Approve Void",
      component: ApproveVoid,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["voucher.voids.reject"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Void/Reject",
      name: "Reject Void",
      component: RejectVoid,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Void Hidden",
        state: "voidHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { voidTransaction, voidTransactionHidden };
