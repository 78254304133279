import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomInput from "components/CustomInput/CustomInput.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { token } from "functions/Helper";
import { bookingApi } from "functions/apis";
import { useState } from "react";
import { formatDateTime } from "functions/Helper";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const sortOptionsList = [
  "PROMO_CODE_ASC",
  "PROMO_CODE_DESC",
  "CREATED_AT_ASC",
  "CREATED_AT_DESC"
];

const useStyles = makeStyles(styles);

export default function OnlineBookingPromoCodesList() {
  // const [loading, setLoading] = useEffect(false);
  const [filter, setFilter] = useState({
    SearchString: "",
    SortOptions: "PROMO_CODE_ASC"
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [pages, setPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const peakHourTripsSignal = React.useRef(new AbortController());

  const getPeakData = () => {
    setLoading(true);

    bookingApi
      .get("OnlineBookingPromoCodes", {
        signal: peakHourTripsSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/OnlineBookingPromoCodes/View/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  {token.is_admin === "True" && (
                    <Link
                      to={"/admin/OnlineBookingPromoCodes/Edit/" + record.id}
                    >
                      <Button color="danger" type="button" value="CANCEL">
                        <EditIcon /> EDIT
                      </Button>
                    </Link>
                  )}
                  <Link
                    to={"/admin/OnlineBookingPromoCodes/Delete/" + record.id}
                  >
                    <Button color="danger" className="like">
                      <DeleteIcon /> DELETE
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPeakData();
  }, [filter, pagination]);

  useEffect(() => {
    getPeakData();
    return () => {
      peakHourTripsSignal.current.abort();
    };
  }, []);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      SearchString: e.target.value === "" ? null : e.target.value
    });

  const handleSortOptionsChanged = e => {
    setFilter({ ...filter, SortOptions: e.target.value });
    // setState({ ...state, CurrencyID: e.target.value });
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Online Booking Promo Code</h4>
          </CardHeader>
          <CardBody>
            {(token["creditmonitoring.commissions.write"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/OnlineBookingPromoCodes/Create"}>
                <Button color="rose" type="button" value="Create">
                  Create Promo Code
                </Button>
              </Link>
            )}
            <GridContainer>
              <GridItem xs={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    SORT BY
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={filter.SortOptions ?? ""}
                    onChange={handleSortOptionsChanged}
                  >
                    {sortOptionsList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record}
                          key={record}
                        >
                          <p>{record}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value: filter.SearchString ?? ""
                  }}
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              pageSize={pagination.pageSize}
              page={pagination.pageIndex - 1}
              onPageChange={page => {
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                });
              }}
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  accessor: "promoCode",
                  Header: "Promo Code"
                },
                {
                  id: "effectiveDate",
                  Header: "Effective Date",
                  accessor: d => formatDateTime(new Date(d.effectiveDate + "Z"))
                },
                {
                  id: "expiryDate",
                  Header: "Expiry Date",
                  accessor: d => formatDateTime(new Date(d.expiryDate + "Z"))
                },
                {
                  id: "currency",
                  Header: "Currency",
                  accessor: d => d.currency.code.toString()
                },
                {
                  accessor: "discountAmount",
                  Header: "Discount Amount"
                },
                {
                  Header: "Minimal Trip",
                  accessor: "minimalTrip"
                },
                {
                  Header: "Limit",
                  accessor: "limit"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
