import React from "react";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

export default function ViewTopUp(props) {
  const { topUp } = props;
  return (
    <>
      <CustomInput
        labelText="No"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: topUp.no
        }}
      />
      <CustomInput
        labelText="Agent"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: topUp.agent.name
        }}
      />
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="Billing Currency"
            disabled
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value:
                topUp.agent.billingCurrency === null
                  ? ""
                  : topUp.agent.billingCurrency.code
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="Balance"
            id="numBalance"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: topUp.balance.toLocaleString()
            }}
          />
        </GridItem>
      </GridContainer>
      <CustomInput
        labelText="Note"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: topUp.note === null ? "" : topUp.note
        }}
      />
    </>
  );
}

ViewTopUp.propTypes = {
  topUp: PropTypes.object
};
