import { Assignment, CheckBox, Cancel, Check } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ReactTable from "react-table";
import { bookingApi } from "functions/apis";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(styles);

const OnlineBookingHomeContentList = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    // id: "",
    searchString: "",
    isActive: null
  });
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [reload, setReload] = useState(false);

  const onlineBookingHomeContentSignal = React.useRef(new AbortController());

  useEffect(() => {
    return () => {
      onlineBookingHomeContentSignal.current.abort();
      setData([]);
      setLoading(false);
      setPages(0);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    bookingApi
      .get("OnlineBookingHomeContents", {
        params: {
          filter: filter,
          pagination
        },
        signal: onlineBookingHomeContentSignal.current.signal
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link
                    to={"/admin/OnlineBookingHomeContent/View/" + record.id}
                  >
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  <Link
                    to={"/admin/OnlineBookingHomeContent/Edit/" + record.id}
                  >
                    <Button color="info" type="submit" className="Edit">
                      <EditIcon /> EDIT
                    </Button>
                  </Link>
                  <Link
                    to={"/admin/OnlineBookingHomeContent/Delete/" + record.id}
                  >
                    <Button color="danger" className="remove">
                      <DeleteIcon /> DELETE
                    </Button>
                  </Link>
                  {record.isActive ? (
                    <Button
                      onClick={() => {
                        bookingApi
                          .get(
                            "OnlineBookingHomeContents/DeactivateContent/" +
                              record.id,
                            {
                              signal:
                                onlineBookingHomeContentSignal.current.signal
                            }
                          )
                          .then(() => {
                            setReload(prev => !prev);
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                      color="danger"
                      className="remove"
                    >
                      <Cancel /> DEACTIVATE
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        bookingApi
                          .get(
                            "OnlineBookingHomeContents/ActiveContent/" +
                              record.id,
                            {
                              signal:
                                onlineBookingHomeContentSignal.current.signal
                            }
                          )
                          .then(() => {
                            setReload(prev => !prev);
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                      color="danger"
                      className="remove"
                    >
                      <CheckBox /> ACTIVATE
                    </Button>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      });
  }, [pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleIsActiveFilterChanged = e =>
    setFilter({
      ...filter,
      isActive: e.target.checked
    });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              ONLINE BOOKING HOME CONTENT
            </h4>
          </CardHeader>
          <CardBody>
            <Link to={"/admin/OnlineBookingHomeContent/Create"}>
              <Button color="rose" type="button" value="Create">
                Create
              </Button>
            </Link>

            <Link to={"/admin/OnlineBookingHomeContent/ChangeSequence"}>
              <Button color="rose" type="button" value="Create">
                Change Sequence
              </Button>
            </Link>

            <GridContainer alignItems="center">
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value:
                      filter.searchString === null ? "" : filter.searchString
                  }}
                />
              </GridItem>

              <GridItem xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={filter.isActive}
                      onChange={handleIsActiveFilterChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Is Active"
                />
              </GridItem>
            </GridContainer>

            <GridItem>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "title",
                    Header: "Title",
                    accessor: d => d.title
                  },
                  {
                    id: "description",
                    Header: "Description",
                    accessor: d => d.description
                  },
                  {
                    id: "isActive",
                    Header: "Is Active",
                    accessor: d => (d.isActive ? "Active" : "Inactive")
                  },
                  {
                    id: "Sequence",
                    Header: "Sequence",
                    accessor: d => d.sequence
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OnlineBookingHomeContentList;
