import React, { useEffect, useRef } from "react";
import Moment from "moment";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewVoucherType from "views/VoucherType/ViewVoucherType";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { coreApi, masterApi, voucherApi } from "functions/apis";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function CraeteDirectIssuance(props) {
  const source = useRef(new AbortController());

  const [voucherTypeList, setVoucherTypeList] = React.useState([]);
  const [currencyList, setCurrencyList] = React.useState([]);
  const [routeList, setRouteList] = React.useState([]);
  const [routeLists, setRouteLists] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    if (
      token["voucher.voucher_types.read"] === "allowed" ||
      token.is_admin === "True"
    )
      voucherApi
        .get("/VoucherTypes", {
          signal: source.current.signal,
          params: {
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 0
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setVoucherTypeList(data.records))
        .catch(error => console.log(error));
    if (token["master.routes.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Routes", {
          signal: source.current.signal,
          params: {
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 0
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setRouteList(data.records))
        .catch(error => console.log(error));
    if (
      token["master.currencies.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Currencies", {
          signal: source.current.signal,
          params: {
            filter: {
              searchString: null,
              publicationStatus: 1
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setCurrencyList(data.records))
        .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);
  const [state, setState] = React.useState({
    voucherTypeID: null,
    quantity: 0,
    remarks: null,
    overrideExpiryDate: false,
    expiryDate: null,
    currencyID: null,
    unitPrice: 0,
    trips: []
  });

  const [voucherType, setVoucherType] = React.useState(null);
  const [issuanceTrips, setIssuanceTrips] = React.useState([]);
  const [scheduleCodeLists, setScheduleCodeLists] = React.useState([]);

  const handleVoucherTypeIDChanged = (e, v) => {
    if (v === null) {
      setState({
        ...state,
        voucherTypeID: null,
        trips: []
      });
      return;
    }
    if (state.voucherTypeID !== v.id)
      setState({
        ...state,
        voucherTypeID: v.id
      });
    voucherApi
      .get("/VoucherTypes/" + v.id, {
        signal: source.current.signal
      })
      .then(data => {
        setVoucherType(data);
        let i;
        let tmpRouteLists = [...routeLists];
        for (i = 0; i < data.trips.length; i++) {
          let trip = data.trips[i];
          let tmpRouteList = [...routeList];
          if (trip.hasSectorRestriction) {
            tmpRouteList = tmpRouteList.filter(
              route => route.sector.id === trip.allowedSector.id
            );
          }
          if (trip.hasRouteRestriction) {
            let allowedRouteIDs = trip.allowedRoutes.map(
              allowedRoute => allowedRoute.route.id
            );
            tmpRouteList = tmpRouteList.filter(route =>
              allowedRouteIDs.includes(route.id)
            );
          }
          tmpRouteLists[i] = tmpRouteList;
        }
        setRouteLists(tmpRouteLists);
        setIssuanceTrips(
          data.trips.map(trip => {
            return {
              voucherTypeTripID: trip.id,
              hasTripRestriction: false,
              routeID: null,
              tripDate: null,
              scheduleCode: null,
              pricing: {
                ticket: 0,
                pdf: 0,
                terminalFee: 0,
                confirmationFee: 0,
                surcharge: 0,
                vtlFee: 0
              }
            };
          })
        );
      })
      .catch(error => console.log(error));
  };

  const handleQuantityChanged = e => {
    let newQuantity = parseInt(e.target.value);
    if (newQuantity !== state.quantity)
      setState({
        ...state,
        quantity: newQuantity
      });
  };

  const handleRemarksChanged = e =>
    setState({
      ...state,
      remarks: e.target.value === "" ? null : e.target.value
    });

  const handleOverrideExpiryDateChanged = e =>
    setState({ ...state, overrideExpiryDate: e.target.checked });

  const handleExpiryDateChanged = e =>
    setState({ ...state, expiryDate: e === "" ? null : e });

  const handleCurrencyIDChanged = e =>
    setState({ ...state, currencyID: e.target.value });

  const handleUnitPriceChanged = e =>
    setState({ ...state, unitPrice: e.target.value });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    voucherApi
      .post(
        "/DirectIssuances",
        {
          ...state,
          trips: issuanceTrips
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const handleHasTripRestrictionChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].hasTripRestriction = newValue;
    if (!newValue) {
      newIssuanceTrips[index].routeID = null;
      newIssuanceTrips[index].tripDate = null;
      newIssuanceTrips[index].scheduleCode = null;
    }
    setIssuanceTrips(newIssuanceTrips);
  };

  let updateScheduleCodeList = (index, newIssuanceTrip) => {
    if (newIssuanceTrip.routeID !== null && newIssuanceTrip.tripDate !== null) {
      let selectedRoute = routeList.find(
        route => route.id === newIssuanceTrip.routeID
      );
      let ports = selectedRoute.code.split(" - ");
      let embarkationPort = ports[0];
      let destinationPort = ports[1];
      if (embarkationPort === "TMFT") embarkationPort = "TMF";
      if (destinationPort === "TMFT") destinationPort = "TMF";
      coreApi
        .get("/Trips", {
          signal: source.current.signal,
          params: {
            embarkation: embarkationPort,
            destination: destinationPort,
            tripDate: Moment(newIssuanceTrip.tripDate).format("yyyyMMDD"),
            status: "active"
          }
        })
        .then(data => {
          let newScheduleCodeLists = [...scheduleCodeLists];
          newScheduleCodeLists[index] = data.data;
          setScheduleCodeLists(newScheduleCodeLists);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleRouteIDChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].routeID = newValue;
    newIssuanceTrips[index].scheduleCode = null;
    setIssuanceTrips(newIssuanceTrips);
    updateScheduleCodeList(index, newIssuanceTrips[index]);
  };

  const handleTripDateChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].tripDate = newValue;
    newIssuanceTrips[index].scheduleCode = null;
    setIssuanceTrips(newIssuanceTrips);
    updateScheduleCodeList(index, newIssuanceTrips[index]);
  };

  const handleScheduleCodeChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].scheduleCode = newValue;
    setIssuanceTrips(newIssuanceTrips);
  };

  const handleTicketChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    let val = parseFloat(newValue);
    newIssuanceTrips[index].pricing.ticket = val;
    setIssuanceTrips(newIssuanceTrips);
  };

  const handlePDFChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    let val = parseFloat(newValue);
    newIssuanceTrips[index].pricing.pdf = val;
    setIssuanceTrips(newIssuanceTrips);
  };

  const handleTerminalFeeChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    let val = parseFloat(newValue);
    newIssuanceTrips[index].pricing.terminalFee = val;
    setIssuanceTrips(newIssuanceTrips);
  };

  const handleSurchargeChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    let val = parseFloat(newValue);
    newIssuanceTrips[index].pricing.surcharge = val;
    setIssuanceTrips(newIssuanceTrips);
  };

  const handleConfirmationFeeChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    let val = parseFloat(newValue);
    newIssuanceTrips[index].pricing.confirmationFee = val;
    setIssuanceTrips(newIssuanceTrips);
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Orders/Pending"} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CREATE DIRECT ISSUANCE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  id="voucherType"
                  onChange={handleVoucherTypeIDChanged}
                  options={voucherTypeList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Voucher Type"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleOverrideExpiryDateChanged}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Override Expiry Date"
                    />
                  </GridItem>
                  {state.overrideExpiryDate === true && (
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={state.expiryDate}
                          onChange={handleExpiryDateChanged}
                          timeFormat={false}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "Expiry Date"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
                </GridContainer>
                <CustomInput
                  labelText="Quantity"
                  id="numQuantity"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: handleQuantityChanged,
                    value: state.quantity
                  }}
                />
                <CustomInput
                  labelText="Remarks"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleRemarksChanged,
                    value: state.remarks === null ? "" : state.remarks
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selCurrencyID"
                        className={classes.selectLabel}
                      >
                        Currency
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          state.currencyID === null ? "" : state.currencyID
                        }
                        onChange={handleCurrencyIDChanged}
                      >
                        {currencyList.map(record => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={record.id}
                              key={record.id}
                            >
                              <b>{record.code}</b>
                              <br />
                              {record.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Unit Price"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleUnitPriceChanged,
                        value: state.unitPrice
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Total"
                      id="total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: (
                          state.quantity * state.unitPrice
                        ).toLocaleString()
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {voucherType !== null &&
                    issuanceTrips.map((issuanceTrip, index) => {
                      return (
                        <GridItem md={4} lg={4} key={index}>
                          <Card>
                            <CardHeader>
                              <b>{voucherType.trips[index].description}</b>
                            </CardHeader>
                            <CardBody>
                              <CustomInput
                                labelText="Ticket"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  onChange: e => {
                                    handleTicketChanged(index, e.target.value);
                                  },
                                  value: issuanceTrip.pricing.ticket
                                }}
                              />
                              <CustomInput
                                labelText="PDF"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  onChange: e => {
                                    handlePDFChanged(index, e.target.value);
                                  },
                                  value: issuanceTrip.pricing.pdf
                                }}
                              />
                              <CustomInput
                                labelText="Terminal Fee"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  onChange: e => {
                                    handleTerminalFeeChanged(
                                      index,
                                      e.target.value
                                    );
                                  },
                                  value: issuanceTrip.pricing.terminalFee
                                }}
                              />
                              <CustomInput
                                labelText="Surcharge"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  onChange: e => {
                                    handleSurchargeChanged(
                                      index,
                                      e.target.value
                                    );
                                  },
                                  value: issuanceTrip.pricing.surcharge
                                }}
                              />
                              <CustomInput
                                labelText="Confirmation Fee"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  onChange: e => {
                                    handleConfirmationFeeChanged(
                                      index,
                                      e.target.value
                                    );
                                  },
                                  value: issuanceTrip.pricing.confirmationFee
                                }}
                              />
                              <CustomInput
                                labelText="Total"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "number",
                                  disabled: true,
                                  value:
                                    issuanceTrip.pricing.ticket +
                                    issuanceTrip.pricing.pdf +
                                    issuanceTrip.pricing.terminalFee +
                                    issuanceTrip.pricing.surcharge +
                                    issuanceTrip.pricing.confirmationFee +
                                    issuanceTrip.pricing.vtlFee
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={issuanceTrip.hasTripRestriction}
                                    onChange={e =>
                                      handleHasTripRestrictionChanged(
                                        index,
                                        e.target.checked
                                      )
                                    }
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Has Trip Restriction"
                              />
                              {issuanceTrip.hasTripRestriction === true && (
                                <>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor={"selRoute" + index}
                                      className={classes.selectLabel}
                                    >
                                      Route
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        issuanceTrip.routeID === null
                                          ? ""
                                          : issuanceTrip.routeID
                                      }
                                      onChange={e =>
                                        handleRouteIDChanged(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      inputProps={{
                                        name: "selRoute" + index,
                                        id: "selRoute" + index
                                      }}
                                    >
                                      {typeof routeLists[index] !==
                                        "undefined" &&
                                        routeLists[index].map(record => {
                                          return (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected
                                              }}
                                              value={record.id}
                                              key={record.id}
                                            >
                                              <p>
                                                <b>{record.code}</b>
                                              </p>
                                              {record.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <Datetime
                                      value={
                                        issuanceTrip.tripDate === null
                                          ? ""
                                          : issuanceTrip.tripDate
                                      }
                                      utc={true}
                                      onChange={e =>
                                        handleTripDateChanged(index, e)
                                      }
                                      timeFormat={false}
                                      dateFormat={"DD/MM/YYYY"}
                                      inputProps={{
                                        placeholder: "Trip Date"
                                      }}
                                    />
                                  </FormControl>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor={"selScheduleCodes" + index}
                                      className={classes.selectLabel}
                                    >
                                      Schedule Code
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        issuanceTrip.scheduleCode === null
                                          ? ""
                                          : issuanceTrip.scheduleCode
                                      }
                                      onChange={e =>
                                        handleScheduleCodeChanged(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      inputProps={{
                                        name: "selScheduleCode" + index,
                                        id: "selScheduleCode" + index
                                      }}
                                    >
                                      {scheduleCodeLists[index] &&
                                        scheduleCodeLists[index].map(record => {
                                          return (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected
                                              }}
                                              value={record.tripTime}
                                              key={record.tripTime}
                                            >
                                              {record.tripTime}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    })}
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {voucherType !== null && (
        <Card>
          <CardBody>
            <ViewVoucherType voucherType={voucherType} />
          </CardBody>
        </Card>
      )}
    </>
  );
}

CraeteDirectIssuance.propTypes = {
  history: PropTypes.object
};
