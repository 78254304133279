import MerchantAgentBookingList from "views/MerchantAgentBooking/MerchantAgentBookingList";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ViewMerchantAgentBooking from "views/MerchantAgentBooking/ViewMerchantAgentBooking";

const merchantAgentBookings = () => {
  return [
    {
      path: "/MerchantAgentBooking/List",
      name: "M Agent Booking",
      icon: AssignmentLateIcon,
      component: MerchantAgentBookingList,
      layout: "/admin"
    }
  ];
};

const merchantAgentBookingsHidden = () => {
  return [
    {
      collapse: true,
      name: "Merchant Agent Booking Hidden",
      state: "merchantAgentBookingHiddenCollapse",
      hideInSidebar: true,
      views: [
        {
          path: "/MerchantAgentBooking/Detail",
          name: "Merchant Agent Booking Detail",
          component: ViewMerchantAgentBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { merchantAgentBookings, merchantAgentBookingsHidden };
