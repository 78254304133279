import React, { useRef } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { orderApi } from "functions/apis";
import { Input } from "@material-ui/core";
import AgentDialog from "components/AgentDialog/AgentDialog";

const useStyles = makeStyles(styles);

export default function BulkRecalculateOrderGST(props) {
  const source = useRef(new AbortController());

  const [showModal, setShowModal] = React.useState(false);
  const [agentName, setAgentName] = React.useState(null);

  const handleAgentIDChanged = e => {
    setAgentName(e.name);
    setShowModal(false);
    setState({ ...state, agentID: e.id });
  };

  const handleStartChanged = e =>
    setState({ ...state, start: e === "" ? null : e });

  const handleUntilChanged = e =>
    setState({ ...state, until: e === "" ? null : e });

  const [state, setState] = React.useState({
    agentID: null,
    start: null,
    until: null
  });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    orderApi
      .post("/Orders/BulkRecalculateGST", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/OrderList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              BULK RECALCULATE ORDER GST
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="selAgent" className={classes.selectLabel}>
                  Agent
                </InputLabel>
                <Input
                  onClick={() => setShowModal(prev => !prev)}
                  className={classes.select}
                  value={agentName ?? ""}
                  disabled
                />
                <AgentDialog
                  openModal={showModal}
                  setOpenModal={val => setShowModal(val)}
                  onSelect={handleAgentIDChanged}
                />
              </FormControl>
              <FormControl fullWidth>
                <Datetime
                  value={state.start}
                  onChange={handleStartChanged}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Start"
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Datetime
                  value={state.until}
                  onChange={handleUntilChanged}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Until"
                  }}
                />
              </FormControl>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

BulkRecalculateOrderGST.propTypes = {
  history: PropTypes.object
};
