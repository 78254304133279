export function GetAgentBookingTransferStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Submitted"
    },
    {
      key: 2,
      description: "Approved"
    },
    {
      key: 3,
      description: "Rejected"
    }
  ];
}

export function GetAgentBookingTransferStatusDescription(value) {
  let agentBookingTransferStatus = GetAgentBookingTransferStatuses().find(
    item => item.key === value
  );
  return agentBookingTransferStatus.description;
}
