import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { travelApi } from "functions/apis";
import MerchantUserList from "./MerchantUserList";

const useStyles = makeStyles(styles);

export default function DetailMerchant(props) {
  const source = useRef(new AbortController());

  const { id } = useParams();

  const [state, setState] = React.useState(null);

  useEffect(() => {
    travelApi
      .get("Merchants/" + id, {
        signal: source.current.signal
      })
      .then(res => setState(res))
      .catch(error => console.log(error));

    return () => {
      source.current.abort();
    };
  }, []);

  const classes = useStyles();

  return (
    state !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>MERCHANT DETAIL</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <Link to={"/admin/Merchant/Edit/" + id}>
                <Button color="rose" type="button" value="Edit">
                  EDIT
                </Button>
              </Link>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.name === null ? "" : state.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.email === null ? "" : state.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Business Category"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.businessCategory === null
                          ? ""
                          : state.businessCategory.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Location"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.country === null ? "" : state.country.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.contactNumber === null ? "" : state.contactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.companyAddress === null
                          ? ""
                          : state.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Company)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.companyContactNumber === null
                          ? ""
                          : state.companyContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.settlementCurrency === null
                          ? ""
                          : state.settlementCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Bank"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.bankName === null ? "" : state.bankName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Bank Branch Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.bankBranchName === null
                          ? ""
                          : state.bankBranchName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Bank Account No"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.bankAccountNo === null ? "" : state.bankAccountNo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Bank Account Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.bankAccountName === null
                          ? ""
                          : state.bankAccountName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Bank Swift Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.bankSwiftCode === null ? "" : state.bankSwiftCode
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {state.publicationStatus !== 0 && <MerchantUserList id={id} />}
        </GridItem>
      </GridContainer>
    )
  );
}

DetailMerchant.propTypes = {
  history: PropTypes.object
};
