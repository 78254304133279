import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { travelApi } from "functions/apis";
import Datetime from "react-datetime";
import ControlGroupDialog from "components/ControlGroupDialog/ControlGroupDialog";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const ViewMerchantInventoryAdjustments = props => {
  const classes = useStyles();

  const [input, setInput] = useState({
    controlGroupID: null,
    date: null,
    balance: "",
    remarks: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [controlGroupData, setControlGroupData] = useState({
    id: "",
    name: ""
  });

  const { id } = useParams();

  const viewMerchantInventoryAdjustmentsSignal = useRef(new AbortController());

  useEffect(() => {
    travelApi
      .get("InventoryAdjustments/" + id, {
        signal: viewMerchantInventoryAdjustmentsSignal.current.signal
      })
      .then(resp => {
        setInput(resp);
        setControlGroupData({
          id: resp.controlGroup.id,
          name: resp.controlGroup.name
        });
      });
    return () => viewMerchantInventoryAdjustmentsSignal.current.abort();
  }, []);

  const handleControlGroupChanged = e => {
    setShowModal(false);
    setControlGroupData({
      id: e.id,
      name: e.name
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              VIEW MERCHANT INVENTORY ADJUSTMENTS
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form>
              <GridItem>
                <FormControl
                  disabled
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="selNationality"
                    className={classes.selectLabel}
                  >
                    Control Group
                  </InputLabel>
                  <Input
                    onClick={() => setShowModal(prev => !prev)}
                    className={classes.select}
                    value={controlGroupData.name ?? ""}
                    disabled
                  />
                  <ControlGroupDialog
                    openModal={showModal}
                    setOpenModal={val => setShowModal(val)}
                    onSelect={handleControlGroupChanged}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Datetime
                    value={new Date(input.date)}
                    onChange={() => {}}
                    timeFormat={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Balance"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: () => {},
                    value: input.balance,
                    type: "number",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Remarks"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: () => {},
                    value: input.remarks,
                    disabled: true
                  }}
                />
              </GridItem>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewMerchantInventoryAdjustments;

ViewMerchantInventoryAdjustments.propTypes = {
  history: PropTypes.object
};
