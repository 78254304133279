export function GetCoreApiCheckInProcessTypes() {
  return [
    {
      key: 0,
      description: "Get Boarding Pass"
    },
    {
      key: 1,
      description: "Cancel Boarding Pass"
    }
  ];
}

export function GetCoreApiCheckInProcessTypeDescription(value) {
  let processType = GetCoreApiCheckInProcessTypes().find(
    item => item.key === value
  );
  return processType.description;
}
