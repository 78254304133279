import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { travelApi } from "functions/apis";
import Datetime from "react-datetime";
import ControlGroupDialog from "components/ControlGroupDialog/ControlGroupDialog";

const useStyles = makeStyles(styles);

const CreateMerchantInventoryAdjustments = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [input, setInput] = useState({
    controlGroupID: null,
    date: null,
    balance: 0,
    remarks: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [controlGroupData, setControlGroupData] = useState({
    id: "",
    name: ""
  });

  const createMerchantInventoryAdjustmentsSignal = useRef(
    new AbortController()
  );

  useEffect(() => {
    return () => createMerchantInventoryAdjustmentsSignal.current.abort();
  }, []);

  const handleDateChanged = e =>
    setInput({
      ...input,
      date: e === "" ? null : e
    });

  const handleBalanceChanged = e =>
    setInput({
      ...input,
      balance: e.target.value === "" ? null : e.target.value
    });

  const handleControlGroupChanged = e => {
    setShowModal(false);
    setControlGroupData({
      id: e.id,
      name: e.name
    });
  };

  const handleRemarksChanged = e =>
    setInput({
      ...input,
      remarks: e.target.value === "" ? null : e.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    const body = {
      ...input,
      controlGroupID: controlGroupData.id
    };
    travelApi
      .post("inventoryAdjustments", body, {
        signal: createMerchantInventoryAdjustmentsSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantInventoryAdjustments/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CREATE MERCHANT INVENTORY ADJUSTMENTS
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selNationality"
                      className={classes.selectLabel}
                    >
                      Control Group
                    </InputLabel>
                    <Input
                      onClick={() => setShowModal(prev => !prev)}
                      className={classes.select}
                      value={controlGroupData.name ?? ""}
                    />
                    <ControlGroupDialog
                      openModal={showModal}
                      setOpenModal={val => setShowModal(val)}
                      onSelect={handleControlGroupChanged}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.date}
                      onChange={handleDateChanged}
                      timeFormat={false}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Date"
                      }}
                      utc
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Balance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleBalanceChanged,
                      value: input.balance,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Remarks"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleRemarksChanged,
                      value: input.remarks
                    }}
                  />
                </GridItem>
              </GridContainer>

              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateMerchantInventoryAdjustments;

CreateMerchantInventoryAdjustments.propTypes = {
  history: PropTypes.object
};
