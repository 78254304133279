import { Assignment } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import ReactTable from "react-table";
import { bookingApi } from "functions/apis";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(styles);

const AppsNotification = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState({
    // id: "",
    searchString: ""
  });
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);

  const appsNotificationSignal = React.useRef(new AbortController());

  useEffect(() => {
    return () => {
      appsNotificationSignal.current.abort();
      setData([]);
      setLoading(false);
      setPages(0);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    bookingApi
      .get("appsnotifications", {
        params: {
          filter: filter,
          pagination
        },
        signal: appsNotificationSignal.current.signal
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/AppsNotification/View/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  <Link to={"/admin/AppsNotification/Edit/" + record.id}>
                    <Button color="info" type="submit" className="Edit">
                      <EditIcon /> EDIT
                    </Button>
                  </Link>
                  <Link to={"/admin/AppsNotification/Delete/" + record.id}>
                    <Button color="danger" className="remove">
                      <DeleteIcon /> DELETE
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      });
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Apps Notification</h4>
          </CardHeader>
          <CardBody>
            <Link to={"/admin/AppsNotification/Create"}>
              <Button color="rose" type="button" value="Create">
                Create
              </Button>
            </Link>

            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <GridItem>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "name",
                    Header: "Name",
                    accessor: d => d.name
                  },
                  {
                    id: "title",
                    Header: "Notification Title",
                    accessor: d => d.title
                  },
                  {
                    id: "description",
                    Header: "Notification Description",
                    accessor: d => d.description
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AppsNotification;
