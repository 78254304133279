import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { voucherApi } from "functions/apis";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function VoidDetailList(props) {
  const { id, status } = props;
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState({});
  useEffect(() => {
    setLoading(true);
    voucherApi
      .get(`/Voids/${id}/Details`, {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        if (status !== 0) setData(data.records);
        else
          setData(
            data.records.map(record => {
              return {
                ...record,
                actions: (
                  <div className="actions-right">
                    <Button
                      color="danger"
                      className="remove"
                      onClick={() => {
                        voucherApi
                          .delete(`/Voids/${id}/Details/${record.id}`, {
                            signal: source.current.signal
                          })
                          .then(() => {
                            setReload({});
                          })
                          .catch(error => {
                            console.log(error);
                          });
                      }}
                    >
                      <DeleteIcon /> DELETE
                    </Button>
                  </div>
                )
              };
            })
          );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const [voucherCodeID, setVoucherCodeID] = React.useState(null);
  const handleVoucherCodeIDChanged = e => setVoucherCodeID(e.target.value);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    voucherApi
      .post(
        `/Voids/${id}/Details`,
        {
          voucherCodeID: voucherCodeID
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => {
        setReload({});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setVoucherCodeID(null);
        setDisabled(false);
      });
  };
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>VOID DETAILS</h4>
      </CardHeader>
      <CardBody>
        {status === 0 && (
          <form onSubmit={handleSubmit}>
            <CustomInput
              labelText="Voucher Code ID"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleVoucherCodeIDChanged,
                value: voucherCodeID === null ? "" : voucherCodeID
              }}
            />
            <Button
              disabled={disabled}
              color="rose"
              type="submit"
              value="Submit"
            >
              Add
            </Button>
          </form>
        )}
        <GridItem xs={3}>
          <CustomInput
            labelText="Search"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: handleSearchStringChanged,
              value: filter.searchString === null ? "" : filter.searchString
            }}
          />
        </GridItem>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              id: "voucherCodeID",
              Header: "Voucher Code ID",
              accessor: d => d.voucherCodeID.toUpperCase()
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

VoidDetailList.propTypes = {
  id: PropTypes.string,
  status: PropTypes.number
};
