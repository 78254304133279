import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { masterApi, creditMonitoringApi } from "functions/apis";

import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function EditCreditInformation(props) {
  const source = useRef(new AbortController());

  const [currencyList, setCurrencyList] = React.useState([]);

  useEffect(() => {
    if (
      token["master.currencies.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Currencies", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setCurrencyList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);
  const [agentCredit, setAgentCredit] = React.useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents/" + id, {
          signal: source.current.signal
        })
        .then(data =>
          setAgentCredit({
            billingCurrencyID:
              data.billingCurrency === null ? null : data.billingCurrency.id,
            balanceAlertThreshold: data.balanceAlertThreshold,
            creditLimitBalance: data.creditLimitBalance
          })
        )
        .catch(error => console.log(error));
  }, [id]);

  const handleBillingCurrencyIDChanged = e => {
    let newBillingCurrencyID = e.target.value;
    if (newBillingCurrencyID !== agentCredit.billingCurrencyID)
      setAgentCredit({
        ...agentCredit,
        billingCurrencyID: newBillingCurrencyID
      });
  };

  const handleCreditLimitBalanceChanged = e => {
    let newCreditLimitBalance = parseFloat(e.target.value);
    if (newCreditLimitBalance !== agentCredit.creditLimitBalance)
      setAgentCredit({
        ...agentCredit,
        creditLimitBalance: newCreditLimitBalance
      });
  };

  const handleBalanceAlertThresholdChanged = e => {
    let newBalanceAlertThreshold = parseFloat(e.target.value);
    if (newBalanceAlertThreshold !== agentCredit.balanceAlertThreshold)
      setAgentCredit({
        ...agentCredit,
        balanceAlertThreshold: newBalanceAlertThreshold
      });
  };

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    creditMonitoringApi
      .put("/Agents/" + id, agentCredit, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + id} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>EDIT CREDIT INFORMATION</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            {agentCredit !== null && (
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selCurrency"
                        className={classes.selectLabel}
                      >
                        Billing Currency
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          agentCredit.billingCurrencyID === null
                            ? ""
                            : agentCredit.billingCurrencyID
                        }
                        onChange={handleBillingCurrencyIDChanged}
                      >
                        {currencyList.map(currency => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={currency.id}
                              key={currency.id}
                            >
                              <b>{currency.code}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Credit Limit Balance"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleCreditLimitBalanceChanged,
                        value:
                          agentCredit.creditLimitBalance === null
                            ? ""
                            : agentCredit.creditLimitBalance
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Balance Alert Threshold"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleBalanceAlertThresholdChanged,
                        value:
                          agentCredit.balanceAlertThreshold === null
                            ? ""
                            : agentCredit.balanceAlertThreshold
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditCreditInformation.propTypes = {
  history: PropTypes.object
};
