import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

import CreateDirectIssuance from "views/DirectIssuance/CreateDirectIssuance.js";
import DirectIssuanceDetail from "views/DirectIssuance/DirectIssuanceDetail";
import DirectIssuanceList from "views/DirectIssuance/DirectIssuanceList.js";

import { token } from "functions/Helper";

const directIssuance = () => {
  let views = [];
  if (
    token["voucher.direct_issuances.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/DirectIssuanceList",
      name: "Direct Issuances",
      icon: ConfirmationNumberIcon,
      component: DirectIssuanceList,
      layout: "/admin"
    });
  if (views.length > 0) return views;
  return [];
};

const directIssuanceHidden = () => {
  let views = [];
  if (
    token["voucher.direct_issuances.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/DirectIssuance/Detail",
      name: "Direct Issuance",
      component: DirectIssuanceDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["voucher.direct_issuances.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/DirectIssuance/Create",
        name: "Create Direct Issuanec",
        component: CreateDirectIssuance,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "BookingType Hidden",
        state: "bookingTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { directIssuance, directIssuanceHidden };
