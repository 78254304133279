import React from "react";
import PropTypes from "prop-types";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

export default function View(props) {
  const { feePolicyGroup } = props;
  return (
    <>
      <CustomInput
        labelText="Code"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: feePolicyGroup.code
        }}
      />
      <CustomInput
        labelText="Name"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: feePolicyGroup.name
        }}
      />
      <CustomInput
        labelText="Common Fee Policy"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: feePolicyGroup.commonFeePolicy.code
        }}
      />
      <CustomInput
        labelText="PeakHour Fee Policy"
        disabled
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: feePolicyGroup.peakHourFeePolicy.code
        }}
      />
    </>
  );
}

View.propTypes = {
  feePolicyGroup: PropTypes.object
};
