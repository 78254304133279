export function GetAgentBookingCancellationStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Submitted"
    },
    {
      key: 2,
      description: "Approved"
    },
    {
      key: 3,
      description: "Rejected"
    }
  ];
}

export function GetAgentBookingCancellationStatusDescription(value) {
  let agentBookingCancellationStatus = GetAgentBookingCancellationStatuses().find(
    item => item.key === value
  );
  return agentBookingCancellationStatus.description;
}
