import React, { useRef } from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { Assignment } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import CardFooter from "components/Card/CardFooter";
import { travelApi } from "functions/apis";
// import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(styles);

export default function MerchantControlGroupDialog(props) {
  const classes = useStyles();
  const [pages, setPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: ""
  });
  const [loading, setLoading] = React.useState(false);

  const { onSelect, setOpenModal, openModal } = props;

  const merchantControlGroupDialogSignal = useRef(new AbortController());

  React.useEffect(() => {
    return () => merchantControlGroupDialogSignal.current.abort();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    travelApi
      .get("/inventoryControlGroups", {
        signal: merchantControlGroupDialogSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <a
                    onClick={() => {
                      onSelect && onSelect(record);
                    }}
                  >
                    <Button color="info" className="like">
                      Select
                    </Button>
                  </a>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [pagination, filter]);

  const handleSearchStringChanged = e => {
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });
  };

  return (
    <Dialog
      open={openModal}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          setOpenModal(false);
        }
      }}
    >
      <Card>
        <CardHeader icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            MERCHANT CONTROL GROUP DIALOG
          </h4>
        </CardHeader>
        <CardBody>
          <GridItem xs={12} md={3}>
            <CustomInput
              labelText="Search"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleSearchStringChanged,
                value: filter.searchString === null ? "" : filter.searchString
              }}
            />
          </GridItem>
          <ReactTable
            manual
            pages={pages}
            page={pagination.pageIndex - 1}
            pageSize={pagination.pageSize}
            onPageChange={page =>
              setPagination({
                pageIndex: page + 1,
                pageSize: pagination.pageSize
              })
            }
            onPageSizeChange={(pageSize, page) => {
              setPagination({ pageSize: pageSize, pageIndex: page + 1 });
            }}
            loading={loading}
            data={data}
            filterable={false}
            columns={[
              {
                Header: "Code",
                accessor: "code"
              },
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
      <CardFooter>
        <Button
          onClick={() => {
            setOpenModal(false);
          }}
          type="submit"
          color="danger"
          size="sm"
          block
        >
          Close
        </Button>
      </CardFooter>
    </Dialog>
  );
}

MerchantControlGroupDialog.propTypes = {
  onSelect: PropTypes.func,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
};
