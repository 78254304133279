/* eslint-disable no-useless-escape */
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useParams } from "react-router-dom";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

const EditOnlineBookingHomeContent = props => {
  const classes = useStyles();

  const { id } = useParams();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [input, setInput] = useState({
    title: "",
    description: "",
    banner: "",
    url: ""
  });

  const editOnlineBookingHomeContentSignal = useRef(new AbortController());

  const getData = () => {
    bookingApi
      .get("OnlineBookingHomeContents/" + id, {
        signal: editOnlineBookingHomeContentSignal.current.signal
      })
      .then(data => {
        setInput({
          ...input,
          title: data.title,
          banner: data.banner,
          description: data.description,
          url: data.action
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData();
    return () => editOnlineBookingHomeContentSignal.current.abort();
  }, []);

  const handleTitleChanged = e => {
    setInput({
      ...input,
      title: e.target.value === "" ? null : e.target.value
    });
  };

  const handleDescriptionChanged = e => {
    setInput({
      ...input,
      description: e.target.value === "" ? null : e.target.value
    });
  };

  const handleBannerChanged = e => {
    setInput({
      ...input,
      banner: e.target.value === "" ? null : e.target.value
    });
  };

  const handleUrlChanged = e => {
    setInput({
      ...input,
      url: e.target.value === "" ? null : e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    const regexPattern = new RegExp(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
    );

    var isValid = true;

    if (input.title == null || input.title == "") {
      isValid = false;
    }

    if (input.description == null || input.title == "") {
      isValid = false;
    }

    if (!regexPattern.test(input.banner)) {
      isValid = false;
    }

    regexPattern.lastIndex = 0;
    if (!regexPattern.test(input.url)) {
      isValid = false;
    }

    if (isValid) {
      bookingApi
        .put("OnlineBookingHomeContents/" + id, input, {
          signal: editOnlineBookingHomeContentSignal.current.signal
        })
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Success To Edit Online Home Content!",
            icon: "success",
            didClose: () => {
              setRedirect(true);
            }
          });
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please check your input!",
        didClose: () => {
          setDisabled(false);
        }
      });
    }
  };

  return redirect ? (
    <Redirect to={"/admin/OnlineBookingHomeContentList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              EDIT ONLINE BOOKING HOME CONTENT
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTitleChanged,
                  value: input.title === null ? "" : input.title
                }}
              />
              <CustomInput
                labelText="Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleDescriptionChanged,
                  value: input.title === null ? "" : input.description
                }}
              />
              <CustomInput
                labelText="Banner URL"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleBannerChanged,
                  value: input.banner === null ? "" : input.banner
                }}
              />

              <CustomInput
                labelText="Web Url"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleUrlChanged,
                  value: input.url === null ? "" : input.url
                }}
              />

              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditOnlineBookingHomeContent;

EditOnlineBookingHomeContent.propTypes = {
  history: PropTypes.object
};
