import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CreateMerchantInventoryAdjustments from "views/MerchantInventoryAdjustment/CreateMerchantInventoryAdjustment";
import MerchantInventoryAdjustmentList from "views/MerchantInventoryAdjustment/MerchantInventoryAdjustmentList";
import ViewMerchantInventoryAdjustments from "views/MerchantInventoryAdjustment/ViewMerchantInventoryAdjustment";

const merchantInventoryAdjustments = () => {
  return [
    {
      path: "/MerchantInventoryAdjustments/List",
      name: "M Inventory Adjustments",
      icon: ConfirmationNumberIcon,
      component: MerchantInventoryAdjustmentList,
      layout: "/admin"
    }
  ];
};

const merchantInventoryAdjustmentsHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantInventoryAdjustments/Create",
      name: "Create Merchant Inventory Adjustments",
      component: CreateMerchantInventoryAdjustments,
      layout: "/admin"
    },
    {
      path: "/MerchantInventoryAdjustments/Detail",
      name: "View Merchant Inventory Adjustments",
      component: ViewMerchantInventoryAdjustments,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchant Inventory Adjustments Hidden",
      state: "merchantInventoryAdjustmentsHidden",
      hideInSidebar: true,
      views
    }
  ];
};

export { merchantInventoryAdjustments, merchantInventoryAdjustmentsHidden };
