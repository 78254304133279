export function GetVoidStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Submitted"
    },
    {
      key: 2,
      description: "Approved"
    },
    {
      key: 3,
      description: "Rejected"
    }
  ];
}

export function GetVoidStatusDescription(value) {
  let voidStatus = GetVoidStatuses().find(item => item.key === value);
  return voidStatus.description;
}
