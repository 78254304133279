import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { bookingApi } from "functions/apis";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const ViewAppsNotificationSection = props => {
  const classes = useStyles();

  const [input, setInput] = useState({
    name: ""
  });

  const { id } = useParams();

  const editAppsNotificationSectionSignal = useRef(new AbortController());

  useEffect(() => {
    return () => editAppsNotificationSectionSignal.current.abort();
  }, []);

  useEffect(() => {
    if (id) {
      bookingApi
        .get("AppsNotificationSections/" + id, {
          signal: editAppsNotificationSectionSignal.current.signal
        })
        .then(resp => {
          setInput({
            ...input,
            name: resp.name ?? ""
          });
        });
    }
  }, [id]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              View Apps Notification Section
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form>
              <CustomInput
                labelText="Section Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.name,
                  disabled: true
                }}
              />
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewAppsNotificationSection;

ViewAppsNotificationSection.propTypes = {
  history: PropTypes.object
};
